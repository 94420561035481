import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {reduxForm, Field, getFormValues} from 'redux-form';
import {map, get, find, omit, debounce, sum} from 'lodash';
import {SurveyQuestionsModalForm} from 'components';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import FormSection from 'shared/components/FormSection';
import HeaderTextField from 'shared/components/HeaderTextField';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';
import {ReactComponent as PexelLogo} from 'assets/icons/pexel.svg';
import ConfirmModal from 'shared/components/ConfirmModal';
import {orderAlphabetically} from 'shared/utils/fields';
import {
    fetchCampaigns,
    saveSurvey,
    fetchSurvey,
    resetToInit,
    fetchMonkeySurveys,
    launchSurvey,
    cancelSurvey,
    reopenSurvey,
    setNotifications,
    populateFormFields,
    fetchSurveysToExclude,
    completeSurvey,
} from 'modules/Survey/reducers/manage';
import formValidation from './formValidation';
import SurveyMonkey from 'modules/Survey/components/SurveyMonkey';
import PushNotifications from 'modules/Survey/components/PushNotifications';
import SurveyPeriod from 'modules/Survey/components/SurveyPeriod';
import UseImageModal from 'modules/Survey/components/UseImageModal';
import SurveyBuckets from 'modules/Survey/components/SurveyBuckets';
import RadioField from 'shared/components/RadioField';
import MultiSelectField from 'shared/components/MultiSelectField';
import {updateSurveyBuckets, calculateBuckets} from 'modules/Survey/reducers/manage';
import {SurveyStatus, SURVEY_STATUS_LABEL} from 'modules/Survey/constants';
import * as types from '../../types/SurveryManageTypes';
import CampaignHeader from 'shared/components/CampaignHeader';
import styles from './styles.module.scss';
import {routes} from 'shared/constants/routes';
import Storage from 'shared/services/storage';
import {Redirect} from 'react-router-dom';
import EditSurveyQuestions from '../../components/EditSurveyQuestions';
import { CONFIRMATION_MESSAGE } from 'shared/constants/message';

const FORM_NAME = 'survey/MANAGE';

const FormStorage = new Storage(FORM_NAME);

class List extends PureComponent<types.SurveyManageProps, types.SurveyManageState> {
    state: types.SurveyManageState = {
        isModalOpen: false,
        confirmCancelModal: false,
        confirmReopenModal: false,
        redirect: false,
        isSurveyQuestionsModalOpen: false,
        isLoading: false,
        showConfirmationModal: false,
        showRelaunchModal: false,
        hasCollectorHash: false,
    };

    componentDidMount() {
        const id = get(this.props, 'match.params.id');
        this.props.fetchSurveysToExclude();
        this.props.fetchCampaigns();
        this.props.fetchMonkeySurveys();
        if (id) {
            this.props.fetchSurvey({id});
        }
        this.loadFormState();

        const collectorHash = get(this.props.formValues, 'surveyMonkeyMobileCollectorHash', '');
        if (id && collectorHash.length == 7) {
            this.setState({ hasCollectorHash: true });
        } else {
            this.setState({ hasCollectorHash: false });
        }
    }

    componentDidUpdate(prevProps) {
        const id = get(this.props, 'match.params.id');
        const prevId = get(prevProps, 'match.params.id');
        const collectorHash = get(this.props.formValues, 'surveyMonkeyMobileCollectorHash', '');

        if (id !== prevId) {
            this.props.fetchSurvey({id});
        }
        if (this.props.successfulCanceled === true) {
            this.setState({
                redirect: true,
            });
        }
        this.preserveFormState();
        if (id && collectorHash.length == 7) {
            this.setState({ hasCollectorHash: true });
        } else {
            this.setState({ hasCollectorHash: false });
        }
    }

    componentWillUnmount() {
        this.props.reset();
        this.props.resetToInit();
        FormStorage.clear();
    }

    preserveFormState = debounce(() => {
        FormStorage.save(this.props.formValues);
    }, 300);

    loadFormState = () => {
        if (this.props.location.pathname.includes('create')) {
            const formState = FormStorage.load();
            this.props.populateFormFields(formState);
        }
    };

    launch = () => {
        const id = get(this.props, 'match.params.id');
        this.props.launchSurvey({surveyId: id});
    };
    reopen = () => {
        const id = get(this.props, 'match.params.id');

        this.setState({
            confirmReopenModal: false,
        });
        if (this.state.showRelaunchModal == true) {
            this.setState({ showRelaunchModal: false });
            this.props.setNotifications({ surveyId: id })
        }

        this.props.reopenSurvey({surveyId: id});
    };
    toogleConfirmCancel = () => {
        this.setState({
            confirmCancelModal: !this.state.confirmCancelModal,
        });
    };
    toggleConfirmReopen = () => {
        this.setState({
            confirmReopenModal: !this.state.confirmReopenModal,
        });
    };
    cancel = () => {
        const id = get(this.props, 'match.params.id');
        this.props.cancelSurvey({surveyId: id});
    };

    redirectToPqaMapper = () => {
        const id = get(this.props, 'match.params.id');
        const { history } = this.props;
            history.push({
            pathname: routes.surveys.pqa(id).path
        });
    }

    handleOpenSurveyQuesitonsModal = () => {
        this.setState({isSurveyQuestionsModalOpen: true});
    }
    submit = () => {
        const countryOfAvailability = this.props.selectedCountry;
        const id = get(this.props, 'match.params.id');
        const {
            formValues: {
                title,
                dateTo,
                description,
                dateFrom,
                dateFromTime,
                dateToTime,
                imageUrl,
                points,
                respondentsLimit,
                pushNoteTitle,
                pushNoteBody,
                pushNoteEnabled,
                pointsIncomplete,
                excludedSurveys,
            },
        } = this.props;
    
        if (this.props.formValues.dateTo && this.props.formValues.dateFrom) {
            const dateTo = new Date(this.props.formValues.dateTo);
            const dateFrom = new Date(this.props.formValues.dateFrom);
            if (dateTo < dateFrom) {
                alert('Date To cannot be before Date From.');
                return;
            }
        }
    
        const excludedSurveyIds = map(excludedSurveys, (survey) => Number(survey.value));
    
        const onSuccess = () => {
            if (!window.location.href.includes("app/surveys/create")) {
                window.location.reload();
            }
        };
    
        if (id) {
            this.props.saveSurvey({
                id,
                title,
                dateTo,
                description,
                dateFrom,
                dateFromTime,
                dateToTime,
                imageUrl,
                points,
                respondentsLimit,
                pushNoteTitle,
                pushNoteBody,
                pushNoteEnabled,
                pointsIncomplete,
                excludedSurveyIds,
                meta: { onSuccess },
            });
    
            return;
        }
    
        this.props.saveSurvey({
            ...omit(this.props.formValues, ['excludedSurveys']),
            surveyMonkeyTitle: find(this.props.monkeySurveys, {
                value: this.props.formValues.surveyMonkeySurveyId,
            }).label,
            excludedSurveyIds,
            countryOfAvailability,
            meta: { onSuccess },
        });
    };
    
    showConfirmationModal = () => {
        this.setState({ showConfirmationModal: true });
    };

    closeConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    };

    showRelaunchModal = () => {
        this.setState({ showRelaunchModal: true });
    };

    closeRelaunchModal = () => {
        this.setState({ showRelaunchModal: false });
    };

    confirmChangeStatusToComplete = () => {
        this.setState({ isLoading: true });
        this.props.completeSurvey({ surveyId: this.props.data["id"] });
        this.setState({ isLoading: false, showConfirmationModal: false });
        window.location.reload()
    };

    render() {
        const { campaigns, handleSubmit, savingStates, fetchingStates, monkeySurveys, cancelingStates, reopenStatus } = this.props;
        const surveyId = get(this.props, 'match.params.id');
        let status = this.props.data.status;
        const dateTo = new Date(this.props.formValues.dateTo);
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const isButtonDisabled = dateTo < currentDate;

        if (status == "CLOSED") {
            const limit = this.props.data.respondentsLimit;
            const count = this.props.data.surveyBuckets.reduce((sum, bucket) => {
                return sum + (bucket.answersNo || 0);
            }, 0);

            status = count >= limit ? "COMPLETED" : "INCOMPLETE";
        }

        if (this.state.redirect) {
            return (
                <Redirect
                    to={{
                        pathname: routes.surveys.default.path,
                        search: this.props.history.location.search,
                    }}
                />
            );
        }

        return (
            <>
                <ConfirmModal
                    paragraph="Are you sure you want to cancel this survey?"
                    isOpen={this.state.confirmCancelModal}
                    onClose={this.toogleConfirmCancel}
                    onConfirm={this.cancel}
                    isLoading={cancelingStates.isLoading}
                    title="Cancel a survey"
                    confirmLabel="Cancel"
                    cancelLabel="Deny"
                />

                <ConfirmModal
                    paragraph={CONFIRMATION_MESSAGE.reopen}
                    isOpen={this.state.confirmReopenModal}
                    onClose={this.toggleConfirmReopen}
                    onConfirm={this.reopen}
                    title="Reopen a survey"
                    confirmLabel="Reopen"
                    cancelLabel="Deny"
                />
                {this.state.showConfirmationModal && (
                    <div 
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <div 
                            style={{
                                backgroundColor: 'white',
                                padding: '20px',
                                borderRadius: '5px',
                                textAlign: 'center',
                                width: '300px',
                                zIndex: 10000,
                            }}
                        >
                            <p>Are you sure you want to change the status to Completed?</p>
                            <button
                                onClick={this.closeConfirmationModal}
                                style={{
                                    margin: '10px',
                                    padding: '10px 20px',
                                    backgroundColor: '#ccc',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={this.confirmChangeStatusToComplete}
                                style={{
                                    margin: '10px',
                                    padding: '10px 20px',
                                    backgroundColor: '#00AA87',
                                    border: 'none',
                                    borderRadius: '5px',
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                )}
                {this.state.showRelaunchModal && (
                    <div 
                        style={{
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <div 
                            style={{
                                backgroundColor: 'white',
                                padding: '20px',
                                borderRadius: '5px',
                                textAlign: 'center',
                                width: '400px',
                                zIndex: 10000,
                            }}
                        >
                            <p>Are you sure you want to relaunch this Survey?</p>
                            <p>This will change the status back to open.</p>
                            <button
                                onClick={this.closeRelaunchModal}
                                style={{
                                    margin: '10px',
                                    padding: '10px 20px',
                                    backgroundColor: '#ccc',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer'
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={this.reopen}
                                style={{
                                    margin: '10px',
                                    padding: '10px 20px',
                                    backgroundColor: '#00AA87',
                                    border: 'none',
                                    borderRadius: '5px',
                                    color: 'white',
                                    cursor: 'pointer'
                                }}
                            >
                                Relaunch
                            </button>
                        </div>
                    </div>
                )}
                <ContentLoader states={fetchingStates} isNew={!surveyId}>
                    <UseImageModal
                            isOpen={this.state.isModalOpen}
                            onClose={() => this.setState({isModalOpen: false})}
                            onAdd={(value) => {
                                this.props.change('imageUrl', value);
                            }
                        }
                    />
                    <div>
                        <form onSubmit={handleSubmit(this.submit)}>
                            <CampaignHeader>
                                <div className="SurveyHeader">
                                    <Field
                                        component={HeaderTextField}
                                        name="title"
                                        placeholder="Enter survey internal name"
                                        className="CampaignHeader__input"
                                    />
                                    <div className={styles.SurverHeaderStatus}>
                                        Current Status:{` ${status}`}
                                    </div>
                                    {((this.props.data.status === SurveyStatus.CANCELED || this.props.data.status === SurveyStatus.CLOSED) && status != "COMPLETED") && (
                                        <div 
                                            onClick={!this.state.isLoading ? this.showConfirmationModal : undefined} 
                                            style={{ 
                                                cursor: this.state.isLoading ? 'not-allowed' : 'pointer', 
                                                color: this.state.isLoading ? 'gray' : 'white',
                                                fontWeight: 'bold',
                                                padding: '10px 20px', 
                                                border: '1px solid #ccc', 
                                                backgroundColor: '#00AA87',
                                                borderRadius: '5px', 
                                                display: 'inline-block',
                                                textAlign: 'center',
                                                margin: '5px',
                                                marginLeft: '10px'
                                            }}
                                            onMouseOver={(e) => {
                                                e.currentTarget.style.backgroundColor = '#008887';
                                            }}
                                            onMouseOut={(e) => {
                                                e.currentTarget.style.backgroundColor = '#00AA87';
                                            }}
                                        >
                                        {this.state.isLoading ? 'Updating...' : 'Change to COMPLETED'}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.CampaignHeaderRight}>
                                    <div className={styles.SurveyButtons}>
                                        <Button
                                            buttonType="button"
                                            onClick={this.redirectToPqaMapper}
                                            disabled={status == "COMPLETED" || status == 'INCOMPLETE'}
                                        >
                                            PQA
                                        </Button>
                                        { (status == "COMPLETED" || status == "INCOMPLETE" || status == "CANCELED") && (
                                            <div
                                                onClick={!this.state.isLoading ? this.showRelaunchModal : undefined}
                                                style={{ 
                                                    cursor: this.state.isLoading ? 'not-allowed' : 'pointer', 
                                                    color: this.state.isLoading ? 'gray' : 'white',
                                                    fontWeight: 'bold',
                                                    padding: '11px 20px', 
                                                    border: '1px solid #ccc', 
                                                    backgroundColor: '#FFB74D',
                                                    borderRadius: '5px', 
                                                    display: 'inline-block',
                                                    textAlign: 'center',
                                                    marginLeft: '10px',
                                                    marginBottom: '10px',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Relaunch
                                            </div>
                                        )}
                                        <EditSurveyQuestions edited={this.props.data.questionsEdited}/>
                                        {this.props.data.status !== SurveyStatus.CLOSED && (
                                            <Button
                                                type="primary"
                                                isLoading={savingStates.isLoading}
                                                disabled={isButtonDisabled || savingStates.isLoading}
                                                title={isButtonDisabled ? "Date To cannot be in the past" : ""}
                                            >
                                                Save
                                            </Button>
                                        )}
                                        {surveyId && this.props.data.status === SurveyStatus.OPEN && (
                                            <Button
                                                buttonType="button"
                                                onClick={this.launch}
                                                isLoading={this.props.launchingStates.isLoading}
                                                disabled={isButtonDisabled || savingStates.isLoading}
                                                title={isButtonDisabled ? "Date To cannot be in the past" : ""}
                                            >
                                                Launch
                                            </Button>
                                        )}
                                        {surveyId && this.props.data.status === SurveyStatus.SCHEDULED && (
                                            <Button
                                                buttonType="button"
                                                onClick={this.toggleConfirmReopen}
                                                isLoading={reopenStatus.isLoading}
                                            >
                                                Reopen
                                            </Button>
                                        )}
                                        {surveyId &&
                                            [SurveyStatus.SCHEDULED, SurveyStatus.LIVE].includes(
                                                this.props.data.status,
                                            ) && (
                                                <Button
                                                    buttonType="button"
                                                    onClick={this.toogleConfirmCancel}
                                                    isLoading={this.props.cancelingStates.isLoading}
                                                    cancelButton={true}
                                                >
                                                    Cancel
                                                </Button>
                                            )}
                                    </div>
                                </div>
                            </CampaignHeader>
                            <div className={styles.ManageSurvey}>
                                <FormSection isActive>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Field
                                                component={AutocompleteField}
                                                name="campaignId"
                                                dataSource={campaigns}
                                                label="Campaign"
                                                className="CampaignHeader__input"
                                                disabled={surveyId}
                                                isRequired
                                            />
                                            <div className="row">
                                                <div className="col-md-10">
                                                    <Field
                                                        component={TextField}
                                                        name="imageUrl"
                                                        label="Image url"
                                                        className="CampaignHeader__input"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <button
                                                        onClick={() => this.setState({isModalOpen: true})}
                                                        type="button"
                                                        className={styles.PexelButton}
                                                    >
                                                        <PexelLogo/>
                                                    </button>
                                                </div>
                                            </div>
                                            <Field
                                                component={TextFieldI18N}
                                                name="description"
                                                label="Description"
                                                className="CampaignHeader__input"
                                                multiLine
                                                props={{ disabled: this.state.hasCollectorHash }}
                                            />
                                            <h2>Survey monkey</h2>
                                            <SurveyMonkey surveyId={surveyId} monkeySurveys={monkeySurveys}/>

                                            <h2>Push notification</h2>
                                            <PushNotifications
                                                readOnly={Boolean(this.props.formValues.status) && this.props.formValues.status !== 'OPEN'} formName={FORM_NAME}/>
                                            <SurveyPeriod
                                                disabled={
                                                    this.props.formValues.status && this.props.formValues.status !== 'OPEN'
                                                }
                                            />

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="points"
                                                        label="Points"
                                                        className="CampaignHeader__input"
                                                        tooltip="Choose the amount of points a user will get for this survey"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="pointsIncomplete"
                                                        label="Points incomplete"
                                                        className="CampaignHeader__input"
                                                        isRequired
                                                    />
                                                </div>
                                                <div className="col-md-4">
                                                    <Field
                                                        component={TextField}
                                                        name="respondentsLimit"
                                                        label="Cap"
                                                        tooltip="If needed you can set a cap for the number of respondents you want this survey to have"
                                                        className="CampaignHeader__input"
                                                        disabled={this.props.formValues.live}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            {!surveyId && (
                                                <div>
                                                    To enable buckets, please save survey first
                                                    <br/>
                                                    <br/>
                                                </div>
                                            )}
                                            {surveyId && (
                                                <SurveyBuckets
                                                    id={surveyId}
                                                    capacity={this.props.formValues.respondentsLimit}
                                                    buckets={this.props.formValues.surveyBuckets}
                                                    live={this.props.formValues.live}
                                                    updatingSurveyBuckets={this.props.updatingSurveyBuckets}
                                                    calculateState={this.props.calculateState}
                                                    updateSurveyBuckets={this.props.updateSurveyBuckets}
                                                    calculateBuckets={this.props.calculateBuckets}
                                                />
                                            )}
                                            <Field
                                                component={MultiSelectField}
                                                dataSource={this.props.surveysToExclude.map((survey) => ({
                                                    value: survey.id,
                                                    label: survey.title,
                                                }))}
                                                disabled={this.props.formValues.live}
                                                name="excludedSurveys"
                                                label="Excluded surveys"
                                            />
                                        </div>
                                    </div>
                                </FormSection>
                            </div>
                        </form>
                    </div>
                </ContentLoader>
            </>
        );
    }
}

const mapStateToProps = (
    {
        surveys: {
            manage: {
                campaigns,
                save,
                toExclude,
                survey,
                monkeySurveys,
                cancel,
                reopen,
                launch,
                updatingSurveyBuckets,
                calculate,
            },
            list,
        },
        ...state
    },
    ownProps,
) => {
    const surveyData = survey.data || {excludedSurveys: []};
    const surveysToExclude = toExclude.data || [];
    return {
        data: surveyData,
        savingStates: save.states,
        launchingStates: launch.states,
        cancelingStates: cancel.states,
        reopenStatus: reopen.states,
        successfulCanceled: cancel.successfulCanceled,
        monkeySurveys: orderAlphabetically(
            map(monkeySurveys.data, ({id, nickname}) => ({
                label: nickname,
                value: id,
            })),
        ),
        fetchingStates: {
            ...survey.states,
            isLoading:
                survey.states.isLoading || monkeySurveys.states.isLoading || campaigns.states.isLoading,
        },
        campaigns: orderAlphabetically(
            map(campaigns.data, ({id, name}) => ({
                label: name,
                value: id,
            })),
        ),
        campaignsStates: campaigns.states,
        isLoading:
            campaigns.states.isLoading || survey.states.isLoading || monkeySurveys.states.isLoading,
        formValues: getFormValues(FORM_NAME)(state) || {},
        initialValues: {
            pushNoteEnabled: true,
            ...surveyData,
            excludedSurveys:
                surveysToExclude
                .filter((survey) =>
                    surveyData.excludedSurveys
                        ? surveyData.excludedSurveys.includes(Number(survey.id))
                        : false
                )
                .map((survey) => ({value: survey.id, label: survey.title})) || []
                },
        surveysToExclude,
        surveysStates: list.states,
        updatingSurveyBuckets,
        calculateState: calculate,
        selectedCountry: state.app.country,
    };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchCampaigns,
            saveSurvey,
            fetchSurvey,
            resetToInit,
            fetchMonkeySurveys,
            fetchSurveysToExclude,
            launchSurvey,
            cancelSurvey,
            reopenSurvey,
            setNotifications,
            updateSurveyBuckets,
            calculateBuckets,
            populateFormFields,
            completeSurvey,
        },
        dispatch,
    );
export default compose<any>(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate: formValidation,
    }),
)(List);
