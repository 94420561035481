import React, { useState, useEffect } from 'react'
import {
    AutocompleteField,
    Button,
    DateTimeField,
    Dialog,
    FieldArray,
    Form,
    ModalFormProps,
    SurveyBulkQuestionsModalForm,
    Table,
    TextField,
} from 'components'
import { SurveyQuestionResponse } from 'modules/surveyM'
import { FormRow } from './FormRow'
import { Header } from './Header'
import { SurveyQuestionFormRow, SurveyQuestionsFormData } from './types'
import { schema } from './validation'

export const SurveyQuestionsModalForm = ({
    defaultValues,
    onCancel,
    onSubmit,
    open,
    title,
}: ModalFormProps<SurveyQuestionsFormData>) => {
    const [isBulkOpen, setBulkOpen] = useState<boolean>(false)
    const [selectedQuestions, selectQuestions] = useState<number[]>([])
    const [bulkEditDefaultValues, setBulkEditDefaultValues] = useState<Partial<SurveyQuestionFormRow>>()

    const handleOpenBulkForm = () => {
        setBulkOpen(true)
    }

    const isSelected = (index: number) => selectedQuestions.findIndex(i => i === index) !== -1

    const onSelect = (index: number) => () => {
        const selectedIndex = selectedQuestions.findIndex(idx => idx === index)
        let newSelected: number[] = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedQuestions, index)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedQuestions.slice(1))
        } else if (selectedIndex === selectedQuestions.length - 1) {
            newSelected = newSelected.concat(selectedQuestions.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedQuestions.slice(0, selectedIndex),
                selectedQuestions.slice(selectedIndex + 1),
            )
        }

        selectQuestions(newSelected)
    }

    const onSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            selectQuestions(Array.from(Array(defaultValues.questions.length).keys()))
            return
        }
        selectQuestions([])
    }

    const handleSubmitBulk = (setValue, questions) => async (data) => {
        const selectedPageNumbers = selectedQuestions.map(idx => defaultValues.questions[idx]?.surveyMonkeyQuestionPageNumber);
    
        const updated = defaultValues.questions.map((v, i) => {
            const isSamePage = selectedPageNumbers.includes(v.surveyMonkeyQuestionPageNumber);
    
            return {
                ...v,
                ...questions[i],
                ...(selectedQuestions.length === 0 || selectedQuestions.includes(i) || isSamePage ? data : {}),
            };
        });
    
        setValue('questions', updated);
        setBulkOpen(false);
        setBulkEditDefaultValues(undefined);
    };
    

    return (
        <Dialog open={open} title={title} onClose={onCancel} maxWidth={false}>
            <Form<SurveyQuestionsFormData> defaultValues={defaultValues} onSubmit={onSubmit} schema={schema}>
                {({ isSubmitDisabled, isSubmitting }, { getValues, setValue, handleSubmit }) => {
                    return (
                        <>
                            <Dialog.Content>
                                <Header
                                    checked={selectedQuestions.length === defaultValues.questions.length}
                                    indeterminate={selectedQuestions.length > 0 && selectedQuestions.length < defaultValues.questions.length}
                                    onSelectAll={onSelectAll}
                                />
                                <FieldArray<SurveyQuestionFormRow> name="questions">
                                    {(field, index) => (
                                        <FormRow
                                            key={field.id}
                                            field={field}
                                            index={index}
                                            isSelected={isSelected(index)}
                                            onChange={onSelect(index)}
                                        />
                                    )}
                                </FieldArray>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button color="default" onClick={onCancel} type="button">
                                    Cancel
                                </Button>
                                <Button color="info" type="button" onClick={handleOpenBulkForm}>
                                    Edit in Bulk
                                </Button>
                                <Button
                                    disabled={isSubmitDisabled}
                                    loading={isSubmitting}
                                    onClick={handleSubmit(onSubmit)}
                                    type="button">
                                    Save
                                </Button>
                            </Dialog.Actions>
                            <SurveyBulkQuestionsModalForm
                                defaultValues={bulkEditDefaultValues}
                                onCancel={() => { setBulkOpen(false) }}
                                onSubmit={handleSubmitBulk(setValue, getValues().questions)}
                                open={isBulkOpen}
                            />
                        </>
                    )
                }}
            </Form>
        </Dialog>
    )
}
