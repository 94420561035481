import React, { useState, useEffect } from 'react';
import MuiBox from '@material-ui/core/Box';
import {TextField as MuiTextField} from '@material-ui/core'
import {Button as MuiButton} from '@material-ui/core'
import { 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@material-ui/core'
import MuiTypography from '@material-ui/core/Typography';
import {
  Form,
  TextField,
  Button,
  DataTable,
  AutocompleteField,
} from 'components';
import { schema } from './validation';
import styles from './styles.module.scss';
import { COUNTRY_OPTIONS, LANGUAGE_OPTIONS, TRUE_FALSE_OPTIONS } from 'shared/types';
import moment from 'moment';
import {  Query } from 'services'
import Modal from 'shared/components/Modal';
import { CheckMark, Close } from 'shared/components/Icons';
import { addPointsToUser, getCurrentPoints, getReferrals, getTotalUserPoints } from 'modules/user/api';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";


const COLUMNS = [
  { field: 'orderDate', headerName: 'Order Date', },
  { field: 'ordered', headerName: 'Ordered', },
  { field: 'sent', headerName: 'Sent', },
  { field: 'cardNumber', headerName: 'Card Number', },
  { field: 'cardPin', headerName: 'Card Pin', },
  { field: 'giftCard', headerName: 'Gift Card', },
  { field: 'expiryDate', headerName: 'Expiry Date', },
  { field: 'giftCardLink', headerName: 'Gift Card Link', },
];

const REFERRAL_COLUMNS = [
  { field: 'id', headerName: 'ID' },
  { field: 'name', headerName: 'Name' },
  { field: 'email', headerName: 'Email' },
  { field: 'phoneNumber', headerName: 'Phonenumber' },
  { field: 'createdDate', headerName: 'Account created' },
  { field: 'totalPoints', headerName: 'Total points earned' },
];

export const UserInfoForm = ({ defaultValues, onSubmit }) => {
  const { id } = useParams<{ id: string }>();
  const [totalPoints, setTotalPoints] = useState<number | null>(null);
  const [currentPoints, setCurrentPoints] = useState<number | null>(null);

  useEffect(() => {
    const fetchTotalPoints = async () => {
      if (!id) return;

      const points = await getTotalpoints(id);
      setTotalPoints(points);
    };

    fetchTotalPoints();
  }, [id]);

  useEffect(() => {
    const fetchCurrentPoints = async () => {
      if (!id) return;

      const points = await getCurrentpoints(id);
      setCurrentPoints(points);
    };

    fetchCurrentPoints();
  }, [id]);

  const getGiftCards = async (query?: Query): Promise<any> => {

    if (!defaultValues.userGiftCards || defaultValues.userGiftCards.length === 0) {
        return {
            content: [],
            totalPages: 0,
            pageable: { pageNumber: 0, pageSize: 0 },
        };
    }

    let mappedUserGiftCards = defaultValues.userGiftCards.map((item, index) => {
      let url = '#'

      if(item.giftCardLink) {
        let tempUrl = item.giftCardLink.replace('https://', '').replace('http://', '');
        url = 'http://' + tempUrl;
      }

      return {
        orderDate: moment(item.purchaseDate).format('DD-MM-YYYY'),
        ordered: item.tradeOrdered ? 'Yes' : 'No',
        sent: item.tradeCardSent || "--",
        cardNumber: item.tradeCardNumber || "--",
        cardPin: item.tradeCardPin || "--",
        giftCardLink: <a href={url} target='_blank' className={styles.giftCardLink}>{item.giftCardLink}</a>,
        giftCardLinkSort: item.giftCardLink,
        giftCard: item.giftCard.title,
        expiryDate: item.tradeCardExpirationDate ? moment(item.tradeCardExpirationDate).format('DD-MM-YYYY') : "--",
      }
    });

    if(query?.sort) {
      mappedUserGiftCards = mappedUserGiftCards.sort((a, b) => {
        let field = query.sort.field

        if(field === 'giftCardLink') {
          field = 'giftCardLinkSort'
        }

        let valueA = a[field];
        let valueB = b[field];

        if (field === 'orderDate' || field === 'expiryDate') {
          valueA = moment(a[field], 'DD-MM-YYYY').toDate();
          valueB = moment(b[field], 'DD-MM-YYYY').toDate();
        }

        if (valueA > valueB) return query.sort.sort === 'asc' ? 1 : -1;
        if (valueA < valueB) return query.sort.sort === 'asc' ? -1 : 1;
        return 0;
      });
    }

    const response = {
      content: mappedUserGiftCards,
      totalPages: 0,
      pageable: { pageNumber: 1, pageSize: 1 },
    };

    return new Promise((resolve, reject) => {
      resolve(response);
    });
  };

  const getReferralData = async (query?: Query): Promise<any> => {
    try {
      const response1 = await getReferrals(defaultValues.id);
  
      if (!Array.isArray(response1)) {
        throw new Error("Invalid response format: expected an array.");
      }
  
      const mappedReferrals = await Promise.all(
        response1.map(async (item: any) => {
          const totalPoints = await getTotalUserPoints(item.id);
          return {
            id: item.id,
            name: item.fullName || "N/A",
            email: item.email || "N/A",
            phoneNumber: item.phone || "--",
            createdDate: moment(item.registerDate).format("DD-MM-YYYY")
              ? moment(item.registerDate).format("DD-MM-YYYY")
              : "N/A",
            totalPoints: totalPoints || 0,
          };
        })
      );

      

      return {
        content: mappedReferrals,
        totalPages: 1,
        pageable: { pageNumber: 1, pageSize: 10 },
      };
    } catch (error) {
      console.error("Error fetching referrals:", error);
  
      return {
        content: [],
        totalPages: 0,
        pageable: { pageNumber: 0, pageSize: 0 },
      };
    }
  };

  const getTotalpoints = async (id): Promise<number | null> => {
    const totalPoints = await getTotalUserPoints(id)
    return totalPoints
  }

  const getCurrentpoints = async (id): Promise<number | null> => {
    const totalPoints = await getCurrentPoints(id)
    return totalPoints
  }
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmOpen, setConfirmOpen] = useState(false);
  const [points, setPoints] = useState('');

  const [isPasswordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');

  const [activeCard, setActiveCard] = useState<'giftCard' | 'referral' | null>('giftCard');

  const style: React.CSSProperties = {
    maxWidth: '50%',
    maxHeight: '50%'
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePointsChange = (event) => {
    setPoints(event.target.value);
  };

  const handleSubmitPoints = (event) => {
    event.preventDefault();
    setConfirmOpen(true);
  };

  const confirmAddPoints = () => {
    addPointsToUser(parseInt(points), defaultValues.id, passwordInput);
    setPoints('');
    setPasswordInput('');
    setConfirmOpen(false);
    handleCloseModal();
  };

  const handlePasswordInputChange = (event) => {
    setPasswordInput(event.target.value);
  };

  const handleAddPointsClick = () => {
    setPasswordPromptOpen(true);
  };

  const verifyPassword = (password) => {
    return true; 
  };

  const handleSubmitPassword = () => {
    if (verifyPassword(passwordInput)) {
      setPasswordPromptOpen(false);
      setModalOpen(true);
      // setPasswordInput('');
    } else {
      alert('Incorrect password. Please try again.');
      setPasswordInput('');
    }
  };

  const handleReferralClick = () => {
    setActiveCard('referral');
  };

  const handleGiftCardClick = () => {
    setActiveCard('giftCard');
  };
  const history = useHistory();

  return (
    <>
    <Dialog open={isPasswordPromptOpen} onClose={() => setPasswordPromptOpen(false)}>
      <DialogTitle>Authenticate</DialogTitle>
      <DialogContent>
        <DialogContentText>Please enter the password to proceed.</DialogContentText>
        <MuiTextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={passwordInput}
            onChange={handlePasswordInputChange}
            style={{marginBottom: '10%'}}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between'}}>
        <MuiButton onClick={() => setPasswordPromptOpen(false)} color="secondary">
          <Close></Close>
        </MuiButton>
        <MuiButton onClick={handleSubmitPassword} color="primary">
          <CheckMark></CheckMark>
        </MuiButton>
        </DialogActions>
    </Dialog>
    <Dialog
    open={isConfirmOpen}
    onClose={() => setConfirmOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Adding Points"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to award ${points} point(s) to ID: ${defaultValues.id}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between'}}>
        <MuiButton onClick={() => setConfirmOpen(false)} color="secondary">
          <Close></Close>
        </MuiButton>
        <MuiButton onClick={confirmAddPoints} color="primary">
          <CheckMark></CheckMark>
        </MuiButton>
      </DialogActions>
    </Dialog>
    <div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal} // Close the modal when backdrop is clicked or escape key is pressed
        aria-labelledby="add-points-modal-title"
        aria-describedby="add-points-modal-description"
        title='Add Points'
        style={style}
      >
        <form onSubmit={handleSubmitPoints}>
          <div>
            <MuiTextField
              label="Points"
              name="points"
              type="number"
              value={points} // Use the state value
              onChange={handlePointsChange} // Set up the change handler
              required
              inputProps={{ 
                max: 10000
              }}
              className={styles.AddPointsInput}
            />
          </div>
          <Button type="submit" className={styles.AddPointsSubmit}>
            Add Points
          </Button>
        </form>
      </Modal>
    </div>
      <div className={styles.UserInfoForm}>
        <div className={styles.UserInfoHeader}>
          <div className={styles.UserInfoHeaderText}>{defaultValues.email}</div>
        </div>
        <div className={styles.UserInfo}>
          <div className={styles.UserInfoCard}>
            <MuiBox>
              <Form<any>
                defaultValues={defaultValues}
                onSubmit={(values) => {
                  const payload = {
                    id: values.id,
                    email: values.email,
                    country: values.country,
                    language: values.language,
                    phone: values.phone,
                    source: values.source,
                    referralAwarded: values.referralAwarded === "Yes" ? true : null,
                    fraudulent: values.fraudulent,
                    username: values.username,
                  }
                  onSubmit(payload)
                  
                }}
                schema={schema}
              >
                {({ isSubmitting, isValid, isSubmitDisabled }) => (
                  <>
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      disabled={!isValid}
                      className={styles.SubmitButton}
                      >
                      Save
                    </Button>
                    <Button
                      type="button"
                      className={styles.AddPointsButton}
                      onClick={handleAddPointsClick}
                      >
                      Add Points
                    </Button>

                    <MuiTypography variant="h2">User Information</MuiTypography>

                    <TextField label="ID" name="id" disabled />
                    <TextField label="Full Name" name="fullname" defaultValue={defaultValues?.fullName} disabled />
                    <TextField label="Username" name="username" disabled />
                    <TextField label="Register Date" name='registerDateDisable' defaultValue={defaultValues?.registerDate && moment(defaultValues.registerDate).format('DD-MM-YYYY')} disabled />
                    <TextField label="Latest OS" name="os" defaultValue={defaultValues?.userDevice?.os} disabled readOnly/>
                   
                    <TextField label="Email" name="email" required/>

                    <AutocompleteField
                      label="Country"
                      name="country"
                      options={COUNTRY_OPTIONS}
                      required
                    />

                    <AutocompleteField
                      label="Language"
                      name="language"
                      options={LANGUAGE_OPTIONS}
                      required
                    />

                    <TextField label="Phone" name="phone" required />
                    <TextField label="Source" name="source" disabled/>

                    <AutocompleteField
                      label="Fraudulent"
                      name="fraudulent"
                      options={TRUE_FALSE_OPTIONS}
                      required
                    />
                    <TextField label="Total Points Earned" name="pointsEarned" defaultValue={totalPoints ?? "0"} disabled />                                    
                    <TextField label="Current Points" name="currentPoints"defaultValue={currentPoints ?? "0"} disabled />    
                    <TextField label="Referral Awarded" name="referralAwarded" disabled />                           
                    <div 
                      onClick={() => defaultValues.panId && history.push(`/app/users/edit/${defaultValues.panId}`)}
                      style={{ cursor: defaultValues.panId ? 'pointer' : 'default' }}
                    >
                      <TextField 
                        label="Referred By" 
                        name="referredBy" 
                        defaultValue={defaultValues.panId ? defaultValues.panId : "Nobody"} 
                        readOnly
                      />
                    </div>                 
                    </>
                )}
              </Form>
            </MuiBox>
          </div>

          <div className={styles.UserGiftCard}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div 
                    style={{ 
                        cursor: 'pointer',
                        border: '1px solid #ccc', 
                        borderRadius: '8px 8px 0 0', 
                        padding: '8px 16px', 
                        backgroundColor: activeCard === 'giftCard' ? 'white' : 'lightgray',
                        borderBottom: 'none',
                        transform: activeCard === 'giftCard' ? 'scale(1.1)' : 'scale(0.9)'
                    }}
                    onClick={handleGiftCardClick}
                >
                    <MuiTypography variant="h1">Gift Cards</MuiTypography>
                </div>
                <div 
                    style={{ 
                        cursor: 'pointer',
                        border: '1px solid #ccc', 
                        borderRadius: '8px 8px 0 0',
                        padding: '8px 16px', 
                        backgroundColor: activeCard === 'referral' ? 'white' : 'lightgray',
                        borderBottom: 'none',
                        transform: activeCard === 'referral' ? 'scale(1.1)' : 'scale(0.9)'
                    }}
                    onClick={handleReferralClick}
                >
                    <MuiTypography variant="h1">Referrals</MuiTypography>
                </div>
            </div>
            {(() => {
              if (defaultValues?.userGiftCards?.length > 0 && activeCard == "giftCard") {
                return (
                  <DataTable<any>
                    key="giftCardTable"
                    columns={COLUMNS}
                    onRowClick={() => {}}
                    requestHandler={getGiftCards}
                  />
                );
              } else if (defaultValues?.userGiftCards?.length <= 0 && activeCard === 'giftCard') {
                return (
                  <div className={styles.NoGiftcard}>
                  <MuiTypography variant="h2">
                      No luck! This user hasn't ordered any gift cards yet.
                  </MuiTypography>
              </div>
                )
              } else if (activeCard == 'referral' && defaultValues?.referrals?.length > 0) {

       
                return (
                  <DataTable<any>
                    key="referralTable"
                    columns={REFERRAL_COLUMNS}
                    onRowClick={() => {}}
                    requestHandler={getReferralData}
              />
                )
              } else {
                return (
                <div className={styles.NoGiftcard}>
                <MuiTypography variant="h2">
                    This user hasn't referred anyone yet.
                </MuiTypography>
            </div>
                )
              }
            })()}
          </div>
        </div>
      </div>
    </>
  );
};
