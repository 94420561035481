import React from 'react'
import MuiBox from '@material-ui/core/Box'
import MuiTypography from '@material-ui/core/Typography'
import MuiCheckbox from '@material-ui/core/Checkbox'

interface HeaderProps {
    checked: boolean
    indeterminate: boolean
    onSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Header = ({ onSelectAll, checked, indeterminate }: HeaderProps) => (
    <MuiBox display="flex" alignItems="center" fontWeight={700}>
        <MuiBox flex="0 0 50px">
        </MuiBox>
        <MuiBox ml={0} flex="0 0 50px">
            Page
        </MuiBox>
        <MuiBox ml={1} flex="0 0 250px" style={{ width: '250px' }}>
            Question
        </MuiBox>
        <MuiBox ml={-2} flex="0 0 50px">
            Position
        </MuiBox>
        <MuiBox ml={4} flex="1 1 0%">
            Label
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            Medium
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            Creative name
        </MuiBox>
        <MuiBox ml={1} flex="1 1 0%">
            Creative link
        </MuiBox>
    </MuiBox>
)
