import { put, takeEvery, select } from 'redux-saga/effects';
import apiService, { getError } from 'shared/services/api';
import pick from 'lodash/pick';
import {
  DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS,
  DISCUSSIONS_MANAGE_FETCH_DISCUSSION,
  DISCUSSIONS_MANAGE_SAVE_DISCUSSION,
  DISCUSSIONS_MANAGE_UPDATE_BUCKETS,
  DISCUSSIONS_MANAGE_CALCULATE_BUCKETS,
  DISCUSSIONS_MANAGE_LAUNCH,
  DISCUSSIONS_MANAGE_FETCH_CLIENTS,
  DISCUSSIONS_MANAGE_CANCEL,
  DISCUSSIONS_MANAGE_REOPEN,
  DISCUSSIONS_MANAGE_RELAUNCH,
} from '../../reducers/manage/';
import { getHourFormatFromSeconds, getHourFromDate, utcDateToLocal } from 'shared/services/date';
import { replace } from 'connected-react-router';
import { routes } from 'shared/constants/routes';

const transformDiscussionForForm = (discussion) => ({
  ...discussion,
  excludedDiscussions: discussion.excludedDiscussionIds,
  dateFrom: utcDateToLocal(discussion.dateFrom),
  dateFromTime: getHourFromDate(utcDateToLocal(discussion.dateFrom)),
  dateTo: utcDateToLocal(discussion.dateTo),
  dateToTime: getHourFromDate(utcDateToLocal(discussion.dateTo)),
  videoDurationMin: getHourFormatFromSeconds(discussion.videoDurationMin),
  videoDurationMax: getHourFormatFromSeconds(discussion.videoDurationMax),
});

function* fetchDiscussion(action) {
  try {
    const { data } = yield apiService({}).get(`/discussions/${action.payload.id}`);
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_DISCUSSION.SUCCESS,
      payload: transformDiscussionForForm(data),
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_DISCUSSION.FAILURE,
      payload: getError(err),
    });
  }
}

function* calculateBuckets(action) {
  try {
    const { data } = yield apiService({}).put(`/discussions/${action.payload.id}/calculate`);
    yield put({
      type: DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.FAILURE,
      payload: getError(err),
    });
  }
}
function* launchDiscussion(action) {
  try {
    yield apiService({}).put(`/discussions/${action.payload.discussionId}/launch`);
    const { data } = yield apiService({}).get(`/discussions/${action.payload.discussionId}`);

    yield put({
      type: DISCUSSIONS_MANAGE_LAUNCH.SUCCESS,
      payload: transformDiscussionForForm(data),
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_LAUNCH.FAILURE,
      payload: getError(err),
    });
  }
}
function* cancelDiscussion(action) {
  try {
    yield apiService({}).put(`/discussions/${action.payload.discussionId}/cancel`);
    const { data } = yield apiService({}).get(`/discussions/${action.payload.discussionId}`);

    yield put({
      type: DISCUSSIONS_MANAGE_CANCEL.SUCCESS,
      payload: transformDiscussionForForm(data),
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_CANCEL.FAILURE,
      payload: getError(err),
    });
  }
}
function* reopenDiscussion(action) {
  try {
    yield apiService({}).put(`/discussions/${action.payload.discussionId}/reopen`);
    const { data } = yield apiService({}).get(`/discussions/${action.payload.discussionId}`);

    yield put({
      type: DISCUSSIONS_MANAGE_REOPEN.SUCCESS,
      payload: transformDiscussionForForm(data),
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_REOPEN.FAILURE,
      payload: getError(err),
    });
  }
}
function* relaunchDiscussion(action) {
  try {
    yield apiService({}).put(`/discussions/${action.payload.discussionId}/relaunch`);
    const { data } = yield apiService({}).get(`/discussions/${action.payload.discussionId}`);

    yield put({
      type: DISCUSSIONS_MANAGE_RELAUNCH.SUCCESS,
      payload: transformDiscussionForForm(data),
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_RELAUNCH.FAILURE,
      payload: getError(err),
    });
  }
}
function* fetchCampaigns() {
  try {
    const { data } = yield apiService({}).get('/campaigns?size=100000&sort=id,desc');
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.SUCCESS,
      payload: data.content,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.FAILURE,
      payload: getError(err),
    });
  }
}

function* fetchClients() {
  try {
    const { data } = yield apiService({}).get('/campaigns/clients?size=100000');
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_CLIENTS.SUCCESS,
      payload: data.content,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_FETCH_CLIENTS.FAILURE,
      payload: getError(err),
    });
  }
}
function* saveDiscussion(action) {
  try {
    let data = {};
    if (!action.id) {
      const { data: createData } = yield apiService({}).post('/discussions', action.payload);
      data = createData;
    } else {
      if(Object.hasOwn(action.payload, 'file')) {
        // action.payload.mediaUrl = `https://thx-discussions-media.s3.eu-west-2.amazonaws.com/${action.id}/${action.payload.file.name}`
        delete action.payload.file
      }
      const { data: saveData } = yield apiService({}).put(
        `/discussions/${action.id}`,
        action.payload,
      );
      data = saveData;
    }
    yield put({
      type: DISCUSSIONS_MANAGE_SAVE_DISCUSSION.SUCCESS,
      payload: data,
    });
    if (!action.id) {
      const { app: { country } } = yield select();
      yield put(replace({ pathname: routes.discussions.questions.edit(data.id).path, search: `?country=${country}` }));
    }
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_SAVE_DISCUSSION.FAILURE,
      payload: getError(err),
    });
  }
}
function* updateBuckets(action) {
  try {
    let data = [];
    if (action.payload.deleteBuckets.length) {
      const { data: deleteData } = yield apiService({}).delete(
        `/discussions/${action.payload.id}/buckets?bucketIds=${action.payload.deleteBuckets.join(
          ',',
        )}`,
      );
      data = deleteData;
    }

    if (action.payload.newBuckets.length) {
      const { data: createData } = yield apiService({}).post(
        `/discussions/${action.payload.id}/buckets`,
        action.payload.newBuckets,
      );
      data = createData;
    }
    if (action.payload.updateBuckets.length) {
      const { data: saveData } = yield apiService({}).put(
        `/discussions/${action.payload.id}/buckets`,
        action.payload.updateBuckets.map((bucket) =>
          pick(bucket, ['id', 'ageFrom', 'ageTo', 'answersCap', 'education', 'gender', 'users']),
        ),
      );
      data = saveData;
    }

    yield put({
      type: DISCUSSIONS_MANAGE_UPDATE_BUCKETS.SUCCESS,
      payload: data,
    });
  } catch (err) {
    yield put({
      type: DISCUSSIONS_MANAGE_UPDATE_BUCKETS.FAILURE,
      payload: getError(err),
    });
  }
}

export default [
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_FETCH_DISCUSSION.REQUEST, fetchDiscussion);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_SAVE_DISCUSSION.REQUEST, saveDiscussion);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_FETCH_CAMPAIGNS.REQUEST, fetchCampaigns);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_FETCH_CLIENTS.REQUEST, fetchClients);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_UPDATE_BUCKETS.REQUEST, updateBuckets);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_CALCULATE_BUCKETS.REQUEST, calculateBuckets);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_LAUNCH.REQUEST, launchDiscussion);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_CANCEL.REQUEST, cancelDiscussion);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_REOPEN.REQUEST, reopenDiscussion);
  },
  function* () {
    yield takeEvery(DISCUSSIONS_MANAGE_RELAUNCH.REQUEST, relaunchDiscussion);
  },
];
