import React, { useState, useEffect, FC } from 'react';
import { Field } from 'redux-form';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import Switch from 'shared/components/Switch';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import Papa from 'papaparse';
import path from 'path';

const languageMapping: { [key: string]: string } = {
    UK: 'EN',
    BE_WL: 'FR',
    BE_FL: 'NL',
    SP: 'SP',
    ES: 'SP',
};

const getMappedCountry = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const country = searchParams.get('country') || 'NL';
    return languageMapping[country] || country;
};

interface PushNotificationsProps {
    readOnly?: boolean;
    formName: string;
}

const PushNotifications: FC<PushNotificationsProps> = ({ readOnly, formName }) => {
    const dispatch = useDispatch();
    
    const pushNoteTitle = useSelector((state: any) => state.form[formName]?.values?.pushNoteTitle || '');
    const pushNoteBody = useSelector((state: any) => state.form[formName]?.values?.pushNoteBody || '');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState(pushNoteTitle);
    const [selectedBody, setSelectedBody] = useState(pushNoteBody);
    const [titleOptions, setTitleOptions] = useState<string[]>([]);
    const [bodyOptions, setBodyOptions] = useState<string[]>([]);

    useEffect(() => {
        const country = getMappedCountry();
        let path = '';
        if (formName == 'discussion/MANAGE') {
          path = '/NotificationsChatboxes.csv';
        } else if (formName == 'survey/MANAGE') {
          path = '/NotificationsSurveys.csv';
        }
        fetch(path)
            .then((response) => response.text())
            .then((csvText) => {
                Papa.parse(csvText, {
                    header: true,
                    skipEmptyLines: true,
                    complete: (result) => {
                        const titles = result.data
                            .filter((row: any) => row.Tags === 'Title')
                            .map((row: any) => row[country]?.trim())
                            .filter(Boolean);

                        const bodies = result.data
                            .filter((row: any) => row.Tags === 'Body')
                            .map((row: any) => row[country]?.trim())
                            .filter(Boolean);

                        setTitleOptions(titles);
                        setBodyOptions(bodies);
                    },
                });
            })
            .catch((error) => console.error('Error loading CSV:', error));
    }, []);

    const randomizeContent = () => {
        const randomTitle = titleOptions[Math.floor(Math.random() * titleOptions.length)] || '';
        const randomBody = bodyOptions[Math.floor(Math.random() * bodyOptions.length)] || '';
        setSelectedTitle(randomTitle);
        setSelectedBody(randomBody);
    };

    const handleConfirm = () => {
        const country = getMappedCountry();

        if (selectedTitle !== '') {
            dispatch(change(formName, 'pushNoteTitle', { [country]: selectedTitle }));
        }
        if (selectedBody !== '') {
            dispatch(change(formName, 'pushNoteBody', { [country]: selectedBody }));
        }

        setModalOpen(false);
    };

    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: '10px' }}>
                <Field component={Switch} label="Enable push notification" readOnly={readOnly} name="pushNoteEnabled" />
                <button type='button' className={styles.openModalButton} onClick={() => setModalOpen(true)}>
                    Inspiration ✨
                </button>
            </div>

            <Field
                component={TextFieldI18N}
                name="pushNoteTitle"
                label="Push notification and in-app title"
                className="CampaignHeader__input"
                isRequired
                maxLength={65}
            />

            <div className={styles.bodyField}>
                <Field
                    component={TextFieldI18N}
                    name="pushNoteBody"
                    label="Push notification body"
                    className="CampaignHeader__input"
                    isRequired
                    multiLine
                    maxLength={240}
                />
                <div>
                    <div className={styles.hint}>
                        Possible placeholders:
                        <br />
                        {`{{username}}, {{name}}, {{points}}, {{timeToEnd}}, {{endDate}}`}
                    </div>
                </div>
            </div>

            {modalOpen && (
                <div className={styles.modalOverlay} onClick={() => setModalOpen(false)}>
                    <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                        <h2>Uninspired?</h2>
                        <label>Notification Title</label>
                        <select
                            className={styles.dropdown}
                            value={selectedTitle}
                            onChange={(e) => setSelectedTitle(e.target.value)}
                        >
                            <option value="">Select an option</option>
                            {titleOptions.map((title, index) => (
                                <option key={index} value={title}>{title}</option>
                            ))}
                        </select>

                        <label>Notification Body</label>
                        <select
                            className={styles.dropdown}
                            value={selectedBody}
                            onChange={(e) => setSelectedBody(e.target.value)}
                        >
                            <option value="">Select an option</option>
                            {bodyOptions.map((body, index) => (
                                <option key={index} value={body}>{body}</option>
                            ))}
                        </select>

                        <button style={{ margin: '10px' }} className={styles.closeButton} onClick={() => setModalOpen(false)}>
                            Close
                        </button>
                        <button type='button' style={{ margin: '10px' }} className={styles.randomizeButton} onClick={randomizeContent}>
                            Randomize
                        </button>
                        <button 
                            type='button' 
                            style={{ margin: '10px' }} 
                            className={styles.confirmButton} 
                            onClick={handleConfirm}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PushNotifications;
