import React from 'react';
import { Field } from 'redux-form';
import FieldLabel from 'shared/components/FieldLabel';
import TextField from 'shared/components/TextField';
import DateField from 'shared/components/DateField';

const SurveyPeriod = ({ disabled }: { disabled?: boolean }) => {
  return (
    <>
      <FieldLabel tooltip="Select the period this survey needs to be open in the app">
        Period*
      </FieldLabel>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="CampaignForm__content-type">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <Field
                  disabled={disabled}
                  component={DateField}
                  name="dateFrom"
                  isRequired
                  label={'Date from'}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <Field
                  disabled={disabled}
                  component={TextField}
                  name="dateFromTime"
                  type="time"
                  isRequired
                  label={'Date from time (hh:mm)'}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <Field
                  disabled={disabled}
                  component={DateField}
                  name="dateTo"
                  isRequired
                  label={'Date to'}
                />
              </div>
              <div className="col-md-6 col-lg-6">
                <Field
                  disabled={disabled}
                  component={TextField}
                  type="time"
                  name="dateToTime"
                  isRequired
                  label={'Date to time (hh:mm)'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyPeriod;
