import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { reduxForm, Field, getFormValues } from 'redux-form';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniqBy from 'lodash/uniqBy';
import get from 'lodash/get';
import Button from 'shared/components/Button';
import ContentLoader from 'shared/components/ContentLoader';
import TextField from 'shared/components/TextField';
import TextFieldI18N from 'shared/components/TextFieldI18N';
import FormSection from 'shared/components/FormSection';
import HeaderTextField from 'shared/components/HeaderTextField';
import AutocompleteField from 'shared/components/AutocompleteFieldNew';
import { ReactComponent as PexelLogo } from 'assets/icons/pexel.svg';
import { orderAlphabetically } from 'shared/utils/fields';
import {
  fetchCampaigns,
  saveDiscussion,
  fetchDiscussion,
  resetToInit,
  launchDiscussion,
  fetchClients,
  updateDiscussionBuckets,
  calculateBuckets,
  cancelDiscussion,
  reopenDiscussion,
  relaunchDiscussion,
} from 'modules/Discussion/reducers/manage';
import { fetchDiscussions } from 'modules/Discussion/reducers/list';
import formValidation from './formValidation';
import PushNotifications from 'modules/Survey/components/PushNotifications';
import SurveyPeriod from 'modules/Survey/components/SurveyPeriod';
import UseImageModal from 'modules/Survey/components/UseImageModal';
import SurveyBuckets from 'modules/Survey/components/SurveyBuckets';
import RadioField from 'shared/components/RadioField';
import { DiscussionStatus, DISCUSSION_STATUS_LABEL } from 'modules/Discussion/constants';
import { getSecondsFromMinutes } from 'shared/services/date';
import QuestionsManageMedia from '../QuestionsManageMedia';
import styles from './styles.module.scss';
import campaignStyles from '../../../Campaign/containers/Manage/styles.module.scss';
import CampaignHeader from 'shared/components/CampaignHeader';
import * as types from '../../types/QuestionsManageTypes';
const FORM_NAME = 'discussion/MANAGE';

class List extends PureComponent<types.QuestionsManageProps, types.QuestionManageState> {
  state: types.QuestionManageState = {
    isModalOpen: false,
    showRelaunchModal: false,
    // previewFileSize: undefined,
  };

  componentDidMount() {
    const id = get(this.props, 'match.params.id');
    this.props.fetchDiscussions({});
    this.props.fetchCampaigns();
    this.props.fetchClients();
    if (id) {
      this.props.fetchDiscussion({ id });
    }
  }

  componentDidUpdate(prevProps) {
    const id = get(this.props, 'match.params.id');
    const prevId = get(prevProps, 'match.params.id');
    if (id !== prevId) {
      this.props.fetchDiscussion({ id });
    }
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.resetToInit();
  }

  showRelaunchModal = () => {
    this.setState({ showRelaunchModal: true });
  };

  closeRelaunchModal = () => {
    this.setState({ showRelaunchModal: false });
  };

  launch = () => {
    const id = get(this.props, 'match.params.id');
    this.props.launchDiscussion({ discussionId: id });
  };

  cancel = () => {
    const id = get(this.props, 'match.params.id');
    this.props.cancelDiscussion({ discussionId: id });
  };

  reopen = () => {
    const id = get(this.props, 'match.params.id');
    this.props.reopenDiscussion({ discussionId: id });
  };

  relaunch = () => {
    const id = get(this.props, 'match.params.id');
    this.props.relaunchDiscussion({ discussionId: id });
    this.setState({ showRelaunchModal: false });
  };

  submit = () => {
    const id = get(this.props, 'match.params.id');
    const { formValues } = this.props;
    const additionalFields = {
      videoDurationMin: getSecondsFromMinutes(formValues.videoDurationMin),
      videoDurationMax: getSecondsFromMinutes(formValues.videoDurationMax),
    };
    if (this.props.formValues.dateTo && this.props.formValues.dateFrom) {
      const dateTo = new Date(this.props.formValues.dateTo);
      const dateFrom = new Date(this.props.formValues.dateFrom);
      if (dateTo < dateFrom) {
          alert('Date To cannot be before Date From.');
          return;
      }
  }

    if (id) {
      this.props.saveDiscussion({
        id,
        ...omit(formValues, ['discussionBuckets']),
        ...additionalFields,
      });

      return;
    }

    this.props.saveDiscussion({
      ...omit(formValues, ['file']),
      ...additionalFields,
      countryOfAvailability: this.props.selectedCountry,
    });
  };

  render() {
    const {
      campaigns,
      handleSubmit,
      savingStates,
      fetchingStates,
      clients,
      formValues,
    } = this.props;
    const discussionId = get(this.props, 'match.params.id');
    const dateTo = new Date(this.props.formValues.dateTo);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const isButtonDisabled = dateTo < currentDate;
    return (
      <ContentLoader states={fetchingStates} isNew={!discussionId}>
        <UseImageModal
          isOpen={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
          onAdd={(value) => {
            this.props.change('imageUrl', value);
          }}
        />
        <div>
          <form onSubmit={handleSubmit(this.submit)}>
            <CampaignHeader>
              <div className="SurveyHeader">
                <Field
                  component={HeaderTextField}
                  name="title"
                  placeholder="Enter discussion internal name"
                  className="CampaignHeader__input"
                />
                <div className={styles.SurverHeaderStatus}>
                  Current Status:{' '}
                  {this.props.data.status && DISCUSSION_STATUS_LABEL[this.props.data.status]}
                </div>
              </div>
              <div className={campaignStyles.CampaignHeaderRight}>
                <div className={styles.SurveyButtons}>
                  {discussionId && (this.props.data.status === DiscussionStatus.SCHEDULED) && (
                    <Button
                      buttonType="button"
                      onClick={this.reopen}
                      isLoading={this.props.launchingStates.isLoading}
                    >
                      Reopen
                    </Button>
                  )}
                  {discussionId && (this.props.data.status === DiscussionStatus.CLOSED || this.props.data.status === DiscussionStatus.CANCELED) && (
                    <div
                        onClick={ this.showRelaunchModal }
                        style={{ 
                            cursor: 'pointer', 
                            color: 'white',
                            fontWeight: 'bold',
                            padding: '11px 20px', 
                            border: '1px solid #ccc', 
                            backgroundColor: '#FFB74D',
                            borderRadius: '5px', 
                            display: 'inline-block',
                            textAlign: 'center',
                            marginLeft: '10px',
                            marginBottom: '10px',
                            marginRight: '10px',
                        }}
                    >
                        Relaunch
                    </div>
                )}
                  <Button
                    type="primary"
                    isLoading={savingStates.isLoading}
                    disabled={isButtonDisabled || savingStates.isLoading}
                    title={isButtonDisabled ? "Date To cannot be in the past" : ""}
                  >
                    Save
                  </Button>
                  {discussionId && this.props.data.status === DiscussionStatus.OPEN && (
                    <Button
                      buttonType="button"
                      onClick={this.launch}
                      isLoading={this.props.launchingStates.isLoading}
                      disabled={isButtonDisabled || savingStates.isLoading}
                      title={isButtonDisabled ? "Date To cannot be in the past" : ""}
                    >
                      Launch
                    </Button>
                  )}
                  {discussionId &&
                    [DiscussionStatus.SCHEDULED, DiscussionStatus.LIVE].includes(
                      this.props.data.status,
                    ) && (
                      <Button
                        buttonType="button"
                        onClick={this.cancel}
                        isLoading={this.props.cancelingStates.isLoading}
                      >
                        Cancel
                      </Button>
                    )}
                </div>
              </div>
            </CampaignHeader>
            {this.state.showRelaunchModal && (
                <div 
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div 
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            borderRadius: '5px',
                            textAlign: 'center',
                            width: '400px',
                            zIndex: 10000,
                        }}
                    >
                        <p>Are you sure you want to relaunch this Survey?</p>
                        <p>This will change the status back to open.</p>
                        <button
                            onClick={this.closeRelaunchModal}
                            style={{
                                margin: '10px',
                                padding: '10px 20px',
                                backgroundColor: '#ccc',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer'
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={this.relaunch}
                            style={{
                                margin: '10px',
                                padding: '10px 20px',
                                backgroundColor: '#00AA87',
                                border: 'none',
                                borderRadius: '5px',
                                color: 'white',
                                cursor: 'pointer'
                            }}
                        >
                            Relaunch
                        </button>
                    </div>
                </div>
            )}
            <div className={styles.ManageDiscussion}>
              <FormSection isActive>
                <div className="row">
                  <div className="col-md-4">
                    <Field
                      component={AutocompleteField}
                      name="campaignId"
                      dataSource={campaigns}
                      label="Campaign"
                      className="CampaignHeader__input"
                      disabled={discussionId}
                      isRequired
                    />
                    <Field
                      component={AutocompleteField}
                      name="client"
                      label="Client"
                      dataSource={clients}
                      isCreatable
                      disabled={discussionId}
                      isRequired
                    />
                    <div className="row">
                      <div className="col-md-10">
                        <Field
                          component={TextField}
                          name="imageUrl"
                          label="Image url"
                          className="CampaignHeader__input"
                          isRequired
                        />
                      </div>
                      <div className="col-md-2">
                        <button
                          onClick={() => this.setState({ isModalOpen: true })}
                          type="button"
                          className={styles.PexelButton}
                        >
                          <PexelLogo />
                        </button>
                      </div>
                    </div>

                    <h2>Push notification</h2>
                    <PushNotifications readOnly={Boolean(this.props.formValues.status) && this.props.formValues.status !== 'OPEN'} formName={FORM_NAME}/>

                    <SurveyPeriod />

                    <div className="row">
                      <div className="col-md-6">
                        <Field
                          component={TextField}
                          name="points"
                          label="Points"
                          className="CampaignHeader__input"
                          tooltip="Choose the amount of points a user will get for this discussion"
                          isRequired
                        />
                      </div>
                      <div className="col-md-6">
                        <Field
                          component={TextField}
                          name="respondentsLimit"
                          label="Cap"
                          tooltip="If needed you can set a cap for the number of respondents you want this discussion to have"
                          className="CampaignHeader__input"
                          disabled={this.props.formValues.live}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    {!discussionId && (
                      <div>
                        To enable buckets, please save discussion first
                        <br />
                        <br />
                      </div>
                    )}
                    {discussionId && (
                      <SurveyBuckets
                        id={discussionId}
                        capacity={this.props.formValues.respondentsLimit}
                        buckets={this.props.formValues.discussionBuckets}
                        live={this.props.formValues.live}
                        updatingSurveyBuckets={this.props.updatingDiscussionBuckets}
                        calculateState={this.props.calculateState}
                        updateSurveyBuckets={this.props.updateDiscussionBuckets}
                        calculateBuckets={this.props.calculateBuckets}
                      />
                    )}
                    <div className="row">
                      <div className="col-md-4 col-lg-4 col-sm-4">
                        <Field
                          name="faceRecognition"
                          label="Face recognition"
                          component={RadioField}
                          options={[
                            {
                              value: true,
                              label: 'Yes',
                            },
                            {
                              value: false,
                              label: 'No',
                            },
                          ]}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4 col-sm-4">
                        <Field
                          name="format"
                          label="Format"
                          component={RadioField}
                          options={[
                            {
                              value: 'VIDEO',
                              label: 'Video',
                            },
                            {
                              value: 'IMAGE',
                              label: 'Image',
                            },
                          ]}
                        />
                      </div>
                    </div>

                    {formValues.format === 'VIDEO' && (
                      <div className="row">
                        <div className="col-md-4">
                          <Field
                            component={TextField}
                            name="videoDurationMin"
                            label="Video Duration Min (mm:ss)"
                            mask="99:99"
                            className="CampaignHeader__input"
                            isRequired
                          />
                        </div>
                        <div className="col-md-4">
                          <Field
                            component={TextField}
                            mask="99:99"
                            name="videoDurationMax"
                            label="Video Duration Max (mm:ss)"
                            className="CampaignHeader__input"
                          />
                        </div>
                        <div className="col-md-4">
                          {formValues.format === 'VIDEO' &&
                            // TODO: Check why it is in form values as string. Ugly hack for now
                            [true, 'true'].includes(formValues.faceRecognition) && (
                              <Field
                                component={TextField}
                                name="faceRecognitionPercentage"
                                label="Face recognition time percentage"
                                className="CampaignHeader__input"
                                suffix="%"
                                type="number"
                                max="100"
                                min="0"
                              />
                            )}
                        </div>
                      </div>
                    )}
                    <Field
                      component={RadioField}
                      disabled
                      options={[
                        {
                          value: 'NORMAL',
                          label: 'Normal',
                        },
                        {
                          value: 'EVEN',
                          label: 'Even',
                        },
                        {
                          value: 'FAST',
                          label: 'Fast',
                        },
                      ]}
                      name="deliveryMode"
                      label="Delivery mode"
                    />
                    <Field
                      component={TextFieldI18N}
                      name="description"
                      label="Description Babbelbox in-app"
                      className="CampaignHeader__input"
                      multiLine
                      isRequired
                    />
                    <QuestionsManageMedia
                      formValues={formValues}
                      discussionId={discussionId}
                      change={this.props.change}
                    />
                  </div>
                </div>
              </FormSection>
            </div>
          </form>
        </div>
      </ContentLoader>
    );
  }
}

const mapStateToProps = (
  {
    discussions: {
      manage: {
        campaigns,
        save,
        discussion,
        launch,
        clients,
        calculate,
        updatingDiscussionBuckets,
        cancel,
      },
      list,
    },
    ...state
  },
  ownProps,
) => {
  const discussionData = discussion.data || {};
  const discussions = list.data || [];
  return {
    data: discussionData,
    savingStates: save.states,
    launchingStates: launch.states,
    cancelingStates: cancel.states,
    fetchingStates: {
      ...discussion.states,
      isLoading: discussion.states.isLoading || campaigns.states.isLoading,
    },
    campaigns: orderAlphabetically(
      map(campaigns.data, ({ id, name }) => ({
        label: name,
        value: id,
      })),
    ),
    campaignsStates: campaigns.states,
    isLoading: campaigns.states.isLoading || discussion.states.isLoading,
    formValues: getFormValues(FORM_NAME)(state) || {},
    initialValues: get(ownProps, 'match.params.id') ? discussionData : { pushNoteEnabled: true },
    discussions,
    discussionsStates: list.states,
    clients: uniqBy(
      orderAlphabetically(
        map(clients.data, (client) => ({
          label: client.name,
          value: client.name,
        })),
      ),
      'value',
    ),
    updatingDiscussionBuckets,
    calculateState: calculate,
    selectedCountry: state.app.country,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCampaigns,
      saveDiscussion,
      fetchDiscussion,
      resetToInit,
      fetchDiscussions,
      launchDiscussion,
      fetchClients,
      updateDiscussionBuckets,
      calculateBuckets,
      cancelDiscussion,
      reopenDiscussion,
      relaunchDiscussion,
    },
    dispatch,
  );

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: formValidation,
  }),
)(List);
