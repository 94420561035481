import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import TextField from 'shared/components/TextField';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchAnswers } from 'modules/Discussion/reducers/answers';
import { dateToReadable, utcDateToLocal } from 'shared/services/date';
import Pagination from 'shared/components/Pagination';
import * as types from '../../types/AnswersTypes';
import styles from './styles.module.scss';


const FORM_NAME = 'discussion/LIST';
class List extends PureComponent<types.DiscussionAnswersProps, types.DiscussionAnswersState> {
  state: types.DiscussionAnswersState = {
    perPage: 15,
    term: '',
    sort: 'id,desc',
  };

  componentDidMount() {
    this.search({});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.search({});
    }
  }

  goToManage = (id) => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.discussions.answers.manage(id).path,
      search: history.location.search,
    });
  };

  onSearch = debounce((e) => {
    this.setState({ term: e.target.value });
    this.search({ term: e.target.value, page: 0 });
  }, 200);

  search = (props) => {
    const search = {
      page: this.props.data.number,
      size: this.state.perPage,
      sort: this.state.sort,
      term: this.state.term,
      ...props,
    };
    this.props.fetchAnswers({
      search,
    });
  };

  getCurrentSortType = () => {
    return this.state.sort.split(',')[1];
  };
  onChangeSort = (colName) => {
    const currentSort = this.getCurrentSortType();
    let sortType = currentSort;
    if (this.isActiveColSort(colName)) {
      sortType = currentSort === 'desc' ? 'asc' : 'desc';
    }

    const sort = `${colName},${sortType}`;
    this.setState({
      sort,
    });
    this.search({ sort });
  };
  isActiveColSort = (colName) => {
    return colName === this.state.sort.split(',')[0];
  };
  onPageChange = (page) => {
    this.search({ page });
  };
  onPerPageChange = (perPage) => {
    this.search({ size: perPage });
    this.setState({ perPage });
  };

  render() {
    const { data, states } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam = urlParams.get("country") ?? "NL";

    const columns = [
      { label: '#', colName: 'id', sortable: true },
      { label: 'Title', colName: 'title', sortable: true },
      { label: 'From', colName: 'date_from', sortable: true },
      { label: 'To', colName: 'date_to', sortable: true },
      { label: 'Points', colName: 'points', sortable: true },
      { label: 'Answers Number', colName: 'answers_number', sortable: true },
      {
        label: 'Respondents Limit',
        colName: 'respondents_limit',
        sortable: true,
      },
    ];

    return (
      <div>
        <ListHeading
          title="Answers"
          right={
            <Field
              component={TextField}
              name="search"
              onChange={this.onSearch}
              placeholder="Type to search"
              noMargin
            />
          }
        ></ListHeading>
        <AppContent>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    {columns.map((col, index) => {
                      return (
                        <Th
                          key={`col-${col.label}`}
                          onClick={col.sortable ? () => this.onChangeSort(col.colName) : null}
                          currentSortType={this.getCurrentSortType()}
                          active={this.isActiveColSort(col.colName)}
                          sortable={col.sortable}
                          short={index === 0 || index === columns.length - 1}
                        >
                          {col.label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data.content, (discussion) => (
                    <Tr
                      key={`discussion-${discussion.id}`}
                      onClick={this.goToManage(discussion.id)}
                    >
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {discussion.id}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {discussion.title}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {dateToReadable(utcDateToLocal(discussion.dateFrom))}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {dateToReadable(utcDateToLocal(discussion.dateTo))}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {discussion.points}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {discussion.answersNumber}
                        </a>    
                      </Td>
                      <Td>
                        <a href={`/app/discussions/answers/${discussion.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                          {discussion.respondentsLimit}
                        </a>    
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <Pagination
                onPageChange={this.onPageChange}
                pages={data.totalPages - 1}
                currentPage={data.number}
                visibleOffset={1}
                perPage={this.state.perPage}
                onPerPageChange={this.onPerPageChange}
              />
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ discussions, ...state }) => ({
  data: discussions.answers.list.data,
  states: discussions.answers.list.states,
  formValues: getFormValues(FORM_NAME)(state),
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchAnswers }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(List);
