import React, { useState, useEffect } from 'react';
import Button from 'shared/components/Button';
import omit from 'lodash/omit';
import min from 'lodash/min';
import { reduxForm } from 'redux-form';
import pick from 'lodash/pick';
import RotateLoader from 'shared/components/RotateLoader';
import AddSurveyBucketModal from '../AddSurveyBucketModal';
import SimpleProgressBar from 'shared/components/SimpleProgressBar';
import Notification from 'shared/components/Notification';
import AddBulkSurveyBucketModal from '../AddBulkSurveyBucketModal';
import { v4 as uuidv4 } from 'uuid';
import { BUCKET_OPTION_TYPES } from 'shared/constants/buckets';
import styles from './styles.module.scss';
import { trim } from 'lodash';

const defaultInitialValues = {
  gender: ['FEMALE', 'MALE'],
  education: [],
};
const defaultInitialBulkValues = {
  gender: {
    FEMALE: true,
    MALE: true,
  },
  genderType: BUCKET_OPTION_TYPES.filtered,
  educationType: BUCKET_OPTION_TYPES.filtered,
};

// TODO: Split this component into smaller ones. Due to lack of time for this
// It is like it is.

interface SurveyBucketsTypes {
  id: string;
  capacity?: number;
  buckets?: {
    ageFrom: number;
    ageTo: number;
    answersCap: number;
    answersNo: number;
    education: string[];
    gender: string[];
    id: number;
    open: boolean;
    possibleAnswersNo: number;
    surveyId: number;
    users: any;
    comment: string;
  }[];
  live: any;
  updatingSurveyBuckets: {
    data: any;
    states: {
      isLoading: boolean;
      isLoaded: boolean;
      hasError: boolean;
    };
  };
  calculateState: {
    data: any;
    reposne: any;
    states: {
      isLoading: boolean;
      isLoaded: boolean;
      hasError: boolean;
    };
  };
  updateSurveyBuckets: (...args: any[]) => void;
  calculateBuckets: (...args: any[]) => void;
}

const SurveyBuckets = ({
  id,
  capacity,
  buckets = [],
  live,
  updatingSurveyBuckets,
  calculateState,
  updateSurveyBuckets,
  calculateBuckets,
}: SurveyBucketsTypes ) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isBulkModalOpen, setBulkModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [initialBulkValues, setInitialBulkValues] = useState(defaultInitialBulkValues);
  const [bucketsToCreate, setBucketsToCreate] = useState([]);
  const [bucketsToUpdate, setBucketsToUpdate] = useState([]);
  const [bucketsToDelete, setBucketsToDelete] = useState([]);

  useEffect(() => {
    setBucketsToCreate([]);
    setBucketsToUpdate([]);
    setBucketsToDelete([]);
  }, [buckets, setBucketsToCreate, setBucketsToUpdate, setBucketsToDelete]);

  const save = () => {
    updateSurveyBuckets({
      id,
      newBuckets: bucketsToCreate.map((bucket) => ({
        ...omit(bucket, ['answersCapPercentage', 'tempId']),
        users: getUserIdsArray(bucket?.users)
      })),
      updateBuckets: bucketsToUpdate.map((bucket) => ({
        ...bucket,
        users: getUserIdsArray(bucket?.users)
      })),
      deleteBuckets: bucketsToDelete,
    });
  };
  const onBulkAdd = (buckets) => {
    setBucketsToCreate([
      ...bucketsToCreate,
      ...buckets.map((bucket) => ({
        ...pick(bucket, ['ageFrom', 'ageTo', 'gender', 'education', 'comment']),
        answersCap: bucket.allocation,
        users: []
      })),
    ]);
  };
  const getUserIdsArray = (users: any) => {
    let ids = []
    if(users && typeof users === 'string') {
      const user_ids_array: string [] = users.split(/[\n\s,]/)
      .filter((item: string) => Boolean(parseInt(trim(item))))

      if(user_ids_array.length) {
        ids = Array.from(Array.from(new Set(user_ids_array)))
      }
    } else if(Array.isArray(users)) {
      ids = users
    }

    return ids
  }
  const onAdd = (bucket) => {
    if (bucket.id) {
      setBucketsToUpdate([...bucketsToUpdate, bucket]);

      return;
    }
    if (bucket.tempId) {
      setBucketsToCreate([
        ...bucketsToCreate.map((existingBucket) =>
          bucket.tempId === existingBucket.tempId ? bucket : existingBucket,
        ),
      ]);
      return;
    }
    setBucketsToCreate([...bucketsToCreate, { ...bucket, tempId: uuidv4() }]);
  };

  const removeBucketToCreate = (bucketToRemove) => () => {
    setBucketsToCreate(bucketsToCreate.filter((bucket) => bucket !== bucketToRemove));
  };
  const removeBucketToUpdate = (bucketToRemove) => () => {
    setBucketsToUpdate(bucketsToUpdate.filter((bucket) => bucket !== bucketToRemove));
  };
  const cancelDelete = (bucketToCancel) => () => {
    setBucketsToDelete(bucketsToDelete.filter((bucketId) => bucketId !== bucketToCancel.id));
  };
  const removeBucket = (bucketToRemove) => () => {
    setBucketsToDelete([...bucketsToDelete, bucketToRemove.id]);
  };
  const fixAnswersCap = () => {
    const existingBuckets = [
      ...buckets.filter(
        (bucket) =>
          !bucketsToDelete.includes(bucket.id) &&
          !bucketsToUpdate.find(({ id }) => id === bucket.id),
      ),
      ...bucketsToUpdate,
    ].filter((bucket) => bucket.answersCap > bucket.possibleAnswersNo);

    setBucketsToUpdate([
      ...existingBuckets.map((bucket) => ({
        ...bucket,
        answersCap: bucket.possibleAnswersNo,
      })),
    ]);
  };
  const splitEvenly = () => {
    const existingBuckets = (buckets ?? []).filter(
      (bucket) =>
        !bucketsToDelete.includes(bucket.id) && !bucketsToUpdate.find(({ id }) => id === bucket.id),
    );
    const totalBuckets = bucketsToCreate.length + existingBuckets.length + bucketsToUpdate.length;
    const splitedCapacity = (capacity / totalBuckets).toFixed(0);
    setBucketsToUpdate([
      ...bucketsToUpdate.map((bucket) => ({
        ...bucket,
        answersCap: splitedCapacity,
      })),
      ...existingBuckets.map((bucket) => ({
        ...bucket,
        answersCap: splitedCapacity,
      })),
    ]);
    setBucketsToCreate(
      bucketsToCreate.map((bucket) => ({
        ...bucket,
        answersCap: splitedCapacity,
      })),
    );
  };
  const calculate = async () => {
    calculateBuckets({ id });
  };

  const totalAnswers = (type = 'answersNo') => {
    const existingBuckets = (buckets ?? []).filter(
      (bucket) =>
        !bucketsToDelete.includes(bucket.id) && !bucketsToUpdate.find(({ id }) => id === bucket.id),
    );
    const totalAnswers = [...bucketsToCreate, ...existingBuckets, ...bucketsToUpdate].map(
      (bucket) => bucket[type],
    );

    if (totalAnswers.length) {
      return totalAnswers.reduce((sum, x) => (x ? Number(sum) + Number(x) : Number(sum)));
    }
    return 0;
  };
  const totalPossibleAnswers = totalAnswers('possibleAnswersNo');
  const totalAnswersCap = totalAnswers('answersCap');
  const canSave = bucketsToCreate.length || bucketsToDelete.length || bucketsToUpdate.length;
  const hasInvalidBuckets = () => {
    const existingBuckets = [
      ...buckets.filter(
        (bucket) =>
          !bucketsToDelete.includes(bucket.id) &&
          !bucketsToUpdate.find(({ id }) => id === bucket.id),
      ),
      ...bucketsToUpdate,
    ].filter((bucket) => bucket.possibleAnswersNo && bucket.answersCap > bucket.possibleAnswersNo);
    return !!existingBuckets.length;
  };
  return (
    <div className={styles.wrapper}>
      {(updatingSurveyBuckets.states.isLoading || calculateState.states.isLoading) && (
        <RotateLoader bg />
      )}
      <AddBulkSurveyBucketModal
        isOpen={isBulkModalOpen}
        onAdd={onBulkAdd}
        capacity={capacity}
        onClose={() => {
          setInitialBulkValues(defaultInitialBulkValues);
          setBulkModalOpen(false);
        }}
        initialValues={initialBulkValues}
      />
      <AddSurveyBucketModal
        isOpen={isModalOpen}
        onAdd={onAdd}
        capacity={capacity}
        onClose={() => {
          setModalOpen(false);
        }}
        initialValues={initialValues}
      />
      <h2>Buckets</h2>

      {!live && (
        <div className={styles.buttons}>
          <Button
            onClick={() => {
              setModalOpen(true);
              setInitialValues(defaultInitialValues);
            }}
            buttonType="button"
            small
          >
            Add
          </Button>
          <Button
            onClick={() => {
              setInitialBulkValues(defaultInitialBulkValues);
              setBulkModalOpen(true);
            }}
            buttonType="button"
            small
          >
            Add bulk
          </Button>
          <Button onClick={splitEvenly} buttonType="button" small>
            Split evenly
          </Button>
          <Button onClick={fixAnswersCap} buttonType="button" small disabled={!hasInvalidBuckets()}>
            Fix Answers Cap
          </Button>
          <Button
            onClick={calculate}
            buttonType="button"
            small
            isLoading={calculateState.states.isLoading}
          >
            Calculate
          </Button>
          <Button onClick={save} buttonType="button" small type="primary" disabled={!canSave}>
            Save
          </Button>
        </div>
      )}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Answers cap</th>
            <th>Possible Answers</th>
            <th>Answers</th>
            <th>Comments</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {buckets
            .filter((bucket) => !bucketsToUpdate.find(({ id }) => id === bucket.id))
            .sort((a, b) => a.ageFrom - b.ageFrom)
            .map((bucket, i) => {
              const isAboutToBeDeleted = bucketsToDelete.includes(bucket.id);

              return (
                <tr key={`bucket-${i}`}>
                  <td>
                    {bucket.id}
                  </td>
                  <td>
                    {bucket.ageFrom} - {bucket.ageTo}
                  </td>
                  <td className={styles.lowerCase}>
                    {Array.isArray(bucket.gender) ? bucket.gender.join(', ') : bucket.gender}
                  </td>
                  <td>
                    <span
                      className={
                        bucket.answersCap > bucket.possibleAnswersNo
                          ? styles.bucketError
                          : styles.bucketGood
                      }
                    >
                      {bucket.answersCap}
                    </span>
                  </td>
                  <td>{bucket.possibleAnswersNo}</td>
                  <td>{bucket.answersNo || 0}</td>
                  {live && (
                    <>
                      <td>{bucket.open ? 'Open' : 'Closed'}</td>
                      <td>
                        <SimpleProgressBar
                          current={bucket.answersNo}
                          max={min([bucket.answersCap, bucket.possibleAnswersNo])}
                        />
                      </td>
                    </>
                    
                  )}
                  {!live && (
                    <>
                      <td>{bucket.comment}</td>
                      <td>
                        {isAboutToBeDeleted && 'Click save to delete'}
                        {!isAboutToBeDeleted && 'Created'}
                      </td>
                      <td>
                        {isAboutToBeDeleted && (
                          <Button buttonType="button" ultraSmall onClick={cancelDelete(bucket)}>
                            cancel
                          </Button>
                        )}
                        {!isAboutToBeDeleted && (
                          <div>
                            <Button buttonType="button" ultraSmall onClick={removeBucket(bucket)} disabled={bucket.answersNo > 0}>
                              remove
                            </Button>
                            <Button
                              buttonType="button"
                              ultraSmall
                              onClick={() => {
                                setInitialValues(bucket);
                                setModalOpen(true);
                              }}
                            >
                              edit
                            </Button>
                          </div>
                        )}
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          {bucketsToCreate
          .sort((a, b) => a.ageFrom - b.ageFrom)
          .map((bucket, i) => (
            <tr key={`bucket-to-create-${i}`}>
              <td>
                {bucket.id}
              </td>
              <td>
                {bucket.ageFrom} - {bucket.ageTo}
              </td>
              <td className={styles.lowerCase}>
                {Array.isArray(bucket.gender) ? bucket.gender.join(', ') : bucket.gender}
              </td>
              <td>{bucket.answersCap}</td>
              <td>{bucket.possibleAnswersNo}</td>
              <td>{bucket.answersNo}</td>

              {!live && (
                <>
                  <td>{bucket.comment}</td>
                  <td>Click save to create</td>
                  <td>
                    <Button buttonType="button" ultraSmall onClick={removeBucketToCreate(bucket)}>
                      remove
                    </Button>
                    <Button
                      buttonType="button"
                      ultraSmall
                      onClick={() => {
                        setInitialValues(bucket);
                        setModalOpen(true);
                      }}
                    >
                      edit
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
          {bucketsToUpdate
          .sort((a, b) => a.ageFrom - b.ageFrom)
          .map((bucket, i) => (
            <tr key={`bucket-to-update-${i}`}>
              <td>
               {bucket.id}
              </td>
              <td>
                {bucket.ageFrom} - {bucket.ageTo}
              </td>
              <td className={styles.lowerCase}>
                {Array.isArray(bucket.gender) ? bucket.gender.join(', ') : bucket.gender}
              </td>
              <td>{bucket.answersCap}</td>
              <td>{bucket.possibleAnswersNo}</td>
              <td>{bucket.answersNo}</td>
              {!live && (
                <>
                  <td>{bucket.comment}</td>
                  <td>Click save to update</td>
                  <td>
                    <Button buttonType="button" ultraSmall onClick={removeBucketToUpdate(bucket)}>
                      cancel
                    </Button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
        <tfoot>
        {(totalPossibleAnswers == 0 && buckets.length > 0)  && (
            <tr>
              <td colSpan={8} align="left">
                <Notification type="warning">
                  Please press calculate to see the amount of possible answers
                </Notification>
              </td>
            </tr>
          )}
          {(totalAnswersCap > totalPossibleAnswers) && totalPossibleAnswers != 0 && (
            <tr>
              <td colSpan={8} align="left">
                <Notification type="error">
                  It seems that your answers cap is bigger than possible answers
                </Notification>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={8} align="left">
            <span className={totalAnswersCap !== capacity ? styles.yellow : ''}>
              Used: {totalAnswersCap}/{capacity}
            </span>
              <br />
              Total possibleAnswers: {totalPossibleAnswers}
              <br />
              <br />
              Total Answers:
              <SimpleProgressBar
                current={totalAnswers()}
                max={min([capacity, totalPossibleAnswers])}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default reduxForm({ form: 'SURVEY_BUCKET' })(SurveyBuckets);
