import React from 'react';
import styles from './styles.module.scss';

interface SurveyBucketFieldTypes {
  input: {
    name: string;
    value: number;
  };
  label: any;//?
  type: string;
  meta: {
    touched: boolean;
    error: any;
  };
  className?: string;
}

export const SurveyBucketField = ({ input, label, type, meta: { touched, error }, className }: SurveyBucketFieldTypes) => {
  const inputElement = (
    <input 
      {...input} 
      type={type} 
      placeholder={label} 
      className={className ? className : styles.field}
      style={className === 'commentsInput' ? { 
        width: '300px', 
      } : {}}
    />
  );

  return className === 'commentsInput' ? (
    inputElement
  ) : (
    <div className={styles.wrapper}>
      {inputElement}
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
};



export default SurveyBucketField;
