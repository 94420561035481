import React, {PureComponent, useEffect} from 'react';
import {map, get, find, omit, debounce} from 'lodash';
import ContentLoader from 'shared/components/ContentLoader';
import Modal from 'shared/components/Modal';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import styles from './styles.module.scss';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';

let timer;
let hasLoaded = false;

const PQA = (props) => {
    const id = get(props, 'match.params.id');
   
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [profileQuestionData, setProfileQuestionData] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');

    const [selectedItem, setSelectedItem] = React.useState("");
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const retrieveSurveyData = async () => {
        const base_url = process.env.REACT_APP_BACKEND_URL;
        const suffix = "survey-pqa/survey"

        let query_string = `?survey_id=${id}`;
        let url = `${base_url}${suffix}${query_string}`;

        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        let response = await fetch(url, {
            method: 'GET',
            headers: {
                "authorization": `${accessToken}`,
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });
        setData(await response.json());
        setIsLoading(false);
        setIsLoaded(true);
    }

    const retrieveProfileQuestions = async () => {
        if(searchTerm.length < 3) {
           setProfileQuestionData([]); 
           return;
        }

        const base_url = process.env.REACT_APP_BACKEND_URL;
        const suffix = "survey-pqa/pqa"

        let query_string = `?profile_question=${searchTerm}&country=${"NL"}`;
        let url = `${base_url}${suffix}${query_string}`;

        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        let response = await fetch(url, {
            method: 'GET',
            headers: {
                "authorization": `${accessToken}`,
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            }
        });
        const jtt = await response.json();
        setProfileQuestionData(jtt);
    }

    const mapProfileQuestions = async () => {
        const survey_questions_post = buildPostRequest();

        const base_url = process.env.REACT_APP_BACKEND_URL;
        const suffix = "survey-pqa/survey/map";

        let query_string = `?survey_id=${id}`;
        let url = `${base_url}${suffix}${query_string}`;

        const currentSession = await Auth.currentSession();
        const accessToken = currentSession.getIdToken().getJwtToken();

        const body = {
            "survey_questions": survey_questions_post
        }

        let response = await fetch(url, {
            method: 'POST',
            headers: {
                "authorization": `${accessToken}`,
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body),
        });
        const json_response = await response.json();
        console.log(json_response); 
        if (json_response.message && json_response.message.startsWith("Error: ")) {
            console.log("Error message received:", json_response.message);
            toast.error(json_response.message);
            // Handle error accordingly
        } else {
            console.log("Response is fine:", json_response);
            toast.success(json_response.message);
            // Handle successful response
        }
    }

    const onModalOpen = (id) => {
        setIsModalOpen(true);
        setSelectedItem(id);
    }

    const onModalClose = async () => {
        setIsModalOpen(false);
    }

    const getTitle = (obj) => {
        const jstitle = JSON.parse(obj.titles);
        return jstitle[obj.country_of_availability]
    }

    const setPqa = (pqaId) => {
        const element = document.getElementById(selectedItem + '-input') as HTMLInputElement; 
        if (element) {
            element.value = pqaId;
        }
        setIsModalOpen(false);
    }

    const buildPostRequest = () => {
        // get all values based on the id list if existen in html
        let survey_questions = [];
        for(let item of data) {
            const isSelectedInput = document.getElementById(item.id) as HTMLInputElement;
            if(isSelectedInput.checked) {
                const post_obj = {};
                const element = document.getElementById(item.id + '-input') as HTMLInputElement; 
                post_obj["survey_question_id"] = item.id;
                if(element.value !== "") {
                    post_obj["profile_question_id"] = element.value;
                }
                survey_questions.push(post_obj);
            }
        }
        console.log(survey_questions);
        return survey_questions;
    }

    useEffect(() => {
        // Reset loading state when ID changes
        setIsLoading(true);
        setIsLoaded(false);
        hasLoaded = false;
    }, [id]);

    useEffect(() => {
        if(!hasLoaded) {
            retrieveSurveyData();
            hasLoaded = true;
            console.log("loading data");
        }

        clearTimeout(timer);

        timer = setTimeout(() => {
            retrieveProfileQuestions();
        }, 200);
    }, [searchTerm, id]);

    return <ContentLoader states={{isLoading, isLoaded}} isNew={!id}>
        <Modal isOpen={isModalOpen} onClose={onModalClose} title='Profile question selector' style={{width: "60%"}}>
            <p className={styles.helpertext}>Search for a profile question based on the questions text</p>
            <input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className={styles.searchInput}
            />
            <>
                <ul className={styles.pqlist}>
                    {profileQuestionData.slice(0, 100).map((item, index) => (
                        <li className={styles.listitem} key={item.id} onClick={() => {
                            setPqa(item.id);
                        }} >{item.id} - {getTitle(item)}</li>
                    ))}
                </ul>
            </>
        </Modal>
        <div className={styles.tableholder}>
            <div className={styles.btnholder}>
                <h1>Profile Question Mapper</h1>
                <button className={styles.thxbutton} onClick={mapProfileQuestions}>Map</button>
            </div>
            <p className={styles.pqahint}>Select questions that are needed for the profile question mapping.</p>
            <p className={styles.pqahint}>If no profile question has been selected to map to, new profile question(s) will be created.</p>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Question</Th>
                        <Th>Profile Question</Th>
                        <Th>Use</Th>
                    </Tr>
                </Thead>
                <Tbody>
                {data.map((item, index) => (
                    <Tr key={index}>
                        <Td>{item.headings[0].heading}</Td>
                        <Td><input className={styles.pqinput} id={item.id + '-input'} type="text" placeholder='select pqa' onClick={() => {
                            onModalOpen(item.id);
                        }} defaultValue={item.profile_question_id === 0 ? '' : item.profile_question_id} />
                        <button className={styles.clearbutton} onClick={() => {
                            const element = document.getElementById(item.id + '-input') as HTMLInputElement; 
                            element.value = "";
                        }}><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" fill='black' viewBox="0 0 30 30">
                        <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z"></path>
                        </svg></button>
                        </Td>
                        <Td><input id={item.id} type="checkbox" name={item.id} /></Td>
                    </Tr>
                ))}
                </Tbody>
            </Table>
        </div>
    </ContentLoader>
};

export default PQA;