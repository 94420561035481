import React, { useEffect } from 'react';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';
import FormButtons from 'shared/components/FormButtons';
import TextField from 'shared/components/TextField';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import formValidation from './formValidation';
import CheckboxFields from 'shared/components/CheckboxFields';
import { schema } from './validation';
import styles from './styles.module.scss';

const parseNumber = (val: string) => {
  if (!val) return 0;
  const parsed = parseInt(val, 10);
  return isNaN(parsed) ? 0 : Number(String(parsed).slice(0, 6));
};

const parseFloatNumber = (val: string) => {
  if (!val) return 0;
  const parsed = parseFloat(val);
  if (isNaN(parsed)) return 0;

  const strVal = String(parsed);
  if (strVal.includes('.')) {
    const [intPart, decPart] = strVal.split('.');
    const limitedDecPart = decPart.slice(0, Math.max(0, 6 - intPart.length));
    return Number(`${intPart}.${limitedDecPart}`);
  }

  return Number(strVal.slice(0, 6));
};

interface AddSurveyBucketModalTypes {
  isOpen: boolean;
  onClose: (...args: any[]) => void;
  onAdd: (...args: any[]) => any;
  capacity: number;
  change: (...args: any[]) => any;
  valid: boolean;
  initialValues: {
    education: string[];
    gender: string[];
    id: string | number;
  };
  dirty: boolean;
  reset: (...args: any[]) => void;
}

const AddSurveyBucketModal = ({
  isOpen,
  onClose,
  onAdd,
  capacity,
  change,
  valid,
  initialValues,
  dirty,
  reset,
}: AddSurveyBucketModalTypes) => {
  const values = useSelector((state) => getFormValues('AddSurveyBucketModal')(state));
  const [error, setError] = React.useState(undefined);
  const onCreate = () => {
    if(!error)
      onAdd(values);
      onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  useEffect(() => {
    if (values?.users && typeof values.users === 'string') {
      schema
        .validate(values)
        .then(() => {
          setError('');
        })
        .catch(({ message }) => {
          setError(message);
        });
    } else {
      setError('');
    }
  }, [values?.users]);

  return (
    <Modal title="Add bucket" isOpen={isOpen} onClose={onClose}>      
      <div className={styles.modelBody}>
        <div className="row">
          <div className="col-md-6">
            <Field
              component={TextField}
              name="ageFrom"
              label="Age from"
              parse={parseNumber}
              isRequired
            />
          </div>
          <div className="col-md-6">
            <Field component={TextField} name="ageTo" label="Age to" isRequired parse={parseNumber} />
          </div>
          
          <div className="col-md-12">
            <Field
              component={CheckboxFields}
              isRequired
              options={[
                {
                  value: 'MALE',
                  label: 'Male',
                },
                {
                  value: 'FEMALE',
                  label: 'Female',
                },
              ]}
              name="gender"
              label="Gender"
            />
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
              <Field
                component={TextField}
                name="answersCap"
                isRequired
                parse={parseNumber}
                label="Need completed"
                onChange={(e) => {
                  change('answersCapPercentage', ((e.target.value / capacity) * 100).toFixed(2));
                }}
              />
                Max: {capacity}
              </div>
              <div className="col-md-6">
                <Field
                  component={TextField}
                  name="answersCapPercentage"
                  label="Answers cap %"
                  parse={parseFloatNumber}
                  onChange={(e) => {
                    change('answersCap', (capacity * (e.target.value / 100)).toFixed(0));
                  }}
                  inputProps={{ maxLength: 6 }}
                />
            </div>
            </div>
          </div>
          <div className="col-md-12" style={{marginTop: 20}}>
            <Field component={TextField} name="users" label="User IDs" multiLine={true} meta={{ touched: true, error: error }}/>
          </div>
          <div className="col-md-12" style={{marginTop: 20}}>
            <Field component={TextField} name="comment" label="Comment" multiLine={true} meta={{ touched: true, error: error }}/>
          </div>
        </div>
        <FormButtons>
          <Button buttonType="button" type="primary" onClick={onCreate} disabled={!valid || !dirty || error}>
            {initialValues.id ? 'Edit' : 'Add'}
          </Button>
        </FormButtons>
      </div>
    </Modal>
    
  );
};

export default reduxForm({
  form: 'AddSurveyBucketModal',
  enableReinitialize: true,
  validate: formValidation,
})(AddSurveyBucketModal);
