import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { bindActionCreators, compose } from 'redux';
import ListHeading from 'shared/components/ListHeading';
import Button from 'shared/components/Button';
import TextField from 'shared/components/TextField';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import { fetchSurveys } from 'modules/Survey/reducers/list';
import { dateToReadable, utcDateToLocal } from 'shared/services/date';
import Pagination from 'shared/components/Pagination';
import { SURVEY_STATUS_LABEL } from 'modules/Survey/constants';
import * as types from '../../types/SurveyListTypes';
import styles from './styles.module.scss';
import apiService from 'shared/services/api';

const FORM_NAME = 'survey/LIST';

class List extends PureComponent<types.SurveyListProps, types.SurveryListState> {
  state: types.SurveryListState = { 
    term: undefined, 
    perPage: 15, 
    sort: 'id,desc', 
    totalAnswers: {},
    isLoadingAnswers: true,
  };

  componentDidMount() {
    this.search({});
    if (this.props.data?.content?.length) {
      this.loadTotalAnswers(this.props.data);
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data && this.props.data?.content?.length) {
      this.loadTotalAnswers(this.props.data);
    }
  
    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.search({});
    }
  }
  

  async fetchTotalAnswers(surveyId) {
    try {
      const { data } = await apiService({}).get(`/surveys/${surveyId}/totalAnswers`);
      return data;
    } catch (error) {
      return null;
    }
  }

  async loadTotalAnswers(data) {
    if (!data) {
      data = this.props;
    }
    const totalAnswers = {};
    
    if (!data || !data.content) {
      this.setState({ isLoadingAnswers: false });
      return;
    }
    await Promise.all(
      data.content.map(async (survey) => {
        const answerCount = await this.fetchTotalAnswers(survey.id);
        if (answerCount !== null) {
          totalAnswers[survey.id] = answerCount;
        }
      })
    );
    this.setState({ totalAnswers, isLoadingAnswers: false });
  }
  
  goToCreate = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.surveys.create.path,
      search: history.location.search,
    });
  };
  goToManage = (id) => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.surveys.edit(id).path,
      search: history.location.search,
    });
  };

  onSearch = debounce((e) => {
    this.setState({ term: e.target.value });
    this.search({ term: e.target.value, page: 0 });
  }, 200);

  onPerPageChange = (perPage) => {
    this.search({ size: perPage });
    this.setState({ perPage });
  };

  onPageChange = (page) => {
    this.search({ page });
  };


  search = (props) => {
    const search = {
      page: this.props.data.number,
      size: this.state.perPage,
      term: this.state.term,
      sort: this.state.sort,
      ...props,
    };
    this.props.fetchSurveys({ search });
  };
    getCurrentSortType = () => {
    return this.state.sort.split(',')[1];
  };

  onChangeSort = (colName) => {
    const currentSort = this.getCurrentSortType();
    let sortType = currentSort;
    if (this.isActiveColSort(colName)) {
      sortType = currentSort === 'desc' ? 'asc' : 'desc';
    }

    const sort = `${colName},${sortType}`;
    this.setState({ sort });
    this.search({ sort });
  };

  isActiveColSort = (colName) => {
    return colName === this.state.sort.split(',')[0];
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam = urlParams.get("country") ?? "NL";
    const { data, states } = this.props;
    const { totalAnswers } = this.state;
    const totalAnswersArray = []
    map(data.content, (survey) => (
      totalAnswersArray.push(totalAnswers[survey.id])
    ))
    const columns = [
      { label: '#', colName: 'id', sortable: true },
      { label: 'Title', colName: 'title', sortable: true },
      { label: 'From', colName: 'date_from', sortable: true },
      { label: 'To', colName: 'date_to', sortable: true },
      { label: 'Survey Monkey Title', colName: 'survey_monkey_title', sortable: true },
      { label: 'Reach', colName: 'reach', sortable: false },
      { label: 'Status', colName: 'status', sortable: true },
    ];

    const statusColors = {
      "Live": "#0000FF",
      "Open": "#DFC107",
      "Completed": "#00AA87",
      "Incomplete": "#FF803F",
      "Canceled": "#D62828",
      "Scheduled": "#808080"
    };
    
    return (
      <div>
        <ListHeading
          title="Surveys"
          right={
            <Field
              component={TextField}
              name="search"
              onChange={this.onSearch}
              placeholder="Type to search"
              noMargin
            />
          }
        >
          <Button noMargin type="primary" onClick={this.goToCreate}>
            Add new
          </Button>
        </ListHeading>
        <AppContent>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    {columns.map((col, index) => (
                      <Th
                        key={`col-${col.label}`}
                        onClick={col.sortable ? () => this.onChangeSort(col.colName) : null}
                        currentSortType={this.getCurrentSortType()}
                        active={this.isActiveColSort(col.colName)}
                        sortable={col.sortable}
                        short={index === 0 || index === columns.length - 1}
                      >
                        {col.label}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {map(data.content, (survey) => {
                    const status = SURVEY_STATUS_LABEL[survey.status];
                    let statusColor = ''
                    if ((totalAnswers[survey.id] !== survey.respondentsLimit && status == 'Closed')) {
                      statusColor = statusColors["Incomplete"];
                    } else if ((totalAnswers[survey.id] == survey.respondentsLimit && status == 'Closed')) {
                      statusColor = statusColors["Completed"];
                    } else {
                      statusColor = statusColors[status];
                    }
                    return (
                      <Tr key={`survey-${survey.id}`} onClick={this.goToManage(survey.id)}>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {survey.id}
                          </a>
                        </Td>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {survey.title}
                          </a>
                        </Td>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {dateToReadable(utcDateToLocal(survey.dateFrom))}
                          </a>
                        </Td>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {dateToReadable(utcDateToLocal(survey.dateTo))}
                          </a>
                        </Td>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {survey.surveyMonkeyTitle}
                          </a>
                        </Td>
                        <Td>
                          <a href={`/app/surveys/edit/${survey.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                            {this.state.isLoadingAnswers ? '0' : totalAnswers[survey.id] ?? 0}/{survey.respondentsLimit || '0'}
                          </a>
                        </Td>
                        <Td>
                          <a
                            href={`/app/surveys/edit/${survey.id}?country=${countryParam}`}
                            rel="noopener noreferrer"
                            className={styles.tableLink}
                            style={{ color: statusColor }}
                          >
                            {status === "Closed"
                              ? totalAnswers[survey.id] === survey.respondentsLimit
                                ? "Completed"
                                : "Incomplete"
                              : status}
                          </a>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <Pagination
                onPageChange={this.onPageChange}
                pages={data.totalPages - 1}
                currentPage={data.number}
                visibleOffset={1}
                perPage={this.state.perPage}
                onPerPageChange={this.onPerPageChange}
              />
            </div>
          </div>
        </AppContent>
      </div>
    );    
  }
}

const mapStateToProps = ({ surveys, ...state }) => ({
  data: surveys.list.data,
  states: surveys.list.states,
  formValues: getFormValues(FORM_NAME)(state),
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchSurveys }, dispatch);

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(List);
