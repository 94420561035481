import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import toLower from 'lodash/toLower';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { bindActionCreators, compose } from 'redux';
import TextField from 'shared/components/TextField';
import ListHeading from 'shared/components/ListHeading';
import Button from 'shared/components/Button';
import { routes } from 'shared/constants/routes';
import { Thead, Tbody, Table, Th, Tr, Td } from 'shared/components/Table';
import AppContent from 'shared/components/AppContent';
import {
  fetchCompletionSurveyQuestions,
  deleteCompletionSurveySurveyQuestion,
} from 'modules/CompletionSurveys/reducers/question/list';
import ConfirmModal from 'shared/components/ConfirmModal';
import { Trash } from 'shared/components/Icons';
import IconButton from 'shared/components/IconButton';
import * as types from '../../types/QuestionListTypes';
import styles from './styles.module.scss';

const FORM_NAME = 'completionSurvey/questions/LIST';

class QuestionsList extends PureComponent<types.QuestionListProps, types.QuestionListState> {
  state: types.QuestionListState = { deleteModal: false, deleteModalData: {} };
  componentDidMount() {
    this.props.fetchCompletionSurveyQuestions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deletingStates.isLoading && this.props.deletingStates.isLoaded) {
      this.setState({
        deleteModal: false,
        deleteModalData: {},
      });
    }

    if (prevProps.selectedCountry !== this.props.selectedCountry) {
      this.props.fetchCompletionSurveyQuestions();
    }
  }

  goToCreate = () => {
    const { history } = this.props;
    history.push({
      pathname: routes.completionSurveys.questions.create.path,
      search: history.location.search,
    });
  };

  goToEdit = (id) => () => {
    const { history } = this.props;
    history.push({
      pathname: routes.completionSurveys.questions.edit(id).path,
      search: history.location.search,
    });
  };

  toggleModal = (data = {}) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteModalData: data,
    });
  };

  deleteSurveyQuestion = () => {
    this.props.deleteCompletionSurveySurveyQuestion({
      id: this.state.deleteModalData.questionId,
    });
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const countryParam = urlParams.get("country") ?? "NL";
    const { data, states, formValues, deletingStates } = this.props;
    const lowerValue = toLower(formValues.search);
    const dataToShow = filter(data, ({ titles, filterName }) => {
      const title = flatMap(titles, (value) => value).toString();
      if (includes(toLower(title), lowerValue) || includes(toLower(filterName), lowerValue)) {
        return true;
      }
      return false;
    });
    return (
      <div>
        <ConfirmModal
          paragraph="Are you sure you want to delete selected question?"
          isOpen={this.state.deleteModal}
          isLoading={deletingStates.isLoading}
          onClose={this.toggleModal()}
          onConfirm={this.deleteSurveyQuestion}
          title="Delete a question"
          confirmLabel="Delete"
          cancelLabel="Cancel"
        />
        <ListHeading
          title="Questions"
          right={
            <Field component={TextField} name="search" placeholder="Type to search" noMargin />
          }
        >
          <Button noMargin type="primary" onClick={this.goToCreate}>
            Add new
          </Button>
        </ListHeading>
        <AppContent>
          <div className="row">
            <div className="col-md-12">
              <Table isLoading={states.isLoading}>
                <Thead>
                  <Tr>
                    <Th>Title</Th>
                    <Th>Filter name</Th>
                    <Th>Category</Th>
                    <Th>Type</Th>
                    <Th>Visible</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {map(dataToShow, (question) => (
                    <Tr key={`cat-${question.id}`} onClick={this.goToEdit(question.id)}>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                        {' '}
                        {get(question.titles, 'en') ||
                          get(question.titles, Object.keys(question.titles)[0])}
                      </a>
                      </Td>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                      {question.filterName}
                      </a>
                      </Td>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                        {question?.category ? get(question?.category?.titles, 'en') ||
                          get(question?.category?.titles, Object.keys(question?.category?.titles)[0]) : ''}
                      </a>
                      </Td>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                      {question.type}
                      </a>
                      </Td>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                      {question.visible ? 'Yes' : 'No'}
                      </a>
                      </Td>
                      <Td>
                        <a href={`/app/profile-completion-surveys/questions/edit/${question.id}?country=${countryParam}`} rel="noopener noreferrer" className={styles.tableLink}>
                        <IconButton
                          onClick={this.toggleModal({
                            questionId: question.id,
                          })}
                        >
                          <Trash />
                        </IconButton>
                      </a>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </div>
        </AppContent>
      </div>
    );
  }
}

const mapStateToProps = ({ completionSurveys: { question }, ...state }) => ({
  data: question.list.data,
  states: question.list.states,
  deletingStates: question.list.delete.states,
  formValues: getFormValues(FORM_NAME)(state) || {},
  selectedCountry: state.app.country,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchCompletionSurveyQuestions, deleteCompletionSurveySurveyQuestion },
    dispatch,
  );

export default compose<any>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: FORM_NAME }),
)(QuestionsList);
