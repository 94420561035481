import orderBy from 'lodash/orderBy';

export const orderAlphabetically = (options) => {
  return orderBy(options, [(item) => item.label.toLowerCase()], ['asc']);
};

export const parseNumber = (val) =>
  isNaN(parseInt(val, 10)) ? 0 : parseInt(val, 10);

export const parseFloatNumber = (val) => {
  if (!val) return '';

  let normalized = val.replace(',', '.').replace(/[^0-9.]/g, '');

  const parts = normalized.split('.');
  if (parts.length > 2) {
    normalized = parts.shift() + '.' + parts.join('');
  }

  if (parts.length === 2) {
    parts[1] = parts[1].slice(0, 2);
    normalized = parts.join('.');
  }

  const parsed = parseFloat(normalized);
  return isNaN(parsed) ? '0' : normalized;
};


export const parseComment = (val) => {
  return val.length > 30 ? val.slice(0, 30) : val;
};


