import React, { useEffect, useState } from 'react';
import Modal from 'shared/components/Modal';
import Button from 'shared/components/Button';
import FormButtons from 'shared/components/FormButtons';
import { reduxForm, Field, getFormValues, FieldArray } from 'redux-form';
import RadioField from 'shared/components/RadioField';
import { useSelector } from 'react-redux';
import formValidation from './formValidation';
import AgeBuckets from '../AgeBuckets';
import SurveyBucketOptions from '../SurveyBucketOptions';
import SurveyBucketsPreview from '../SurveyBucketsPreview';
import styles from './styles.module.scss';
import { BUCKET_OPTION_TYPES } from 'shared/constants/buckets';
import { getPreviewBuckets, areBucketsValid } from './helpers';
import { toInteger } from 'lodash';

interface AddBulkSurveyBucketModalTypes {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (...args: any[]) => void;
  capacity: number;
  change: any;
  reset: (...args: any[]) => void;
}

const AddBulkSurveyBucketModal = ({
  isOpen,
  onClose,
  onAdd,
  capacity,
  change,
  reset,
}: AddBulkSurveyBucketModalTypes) => {
  const values = useSelector((state) => getFormValues('AddBulkSurveyBucketModal')(state)) || {};
  const calculatedBuckets = getPreviewBuckets(values, capacity);
  let bucketsValid = areBucketsValid(calculatedBuckets);

  const isAllocationValid = (buckets: any[], capacity: number) => {
    const totalPercentage = buckets.reduce((sum, bucket) => sum + (parseFloat(bucket.allocationPercentage) || 0), 0);
    return toInteger(Math.round(totalPercentage)) === 100;
  };

  if (bucketsValid) {
    bucketsValid = isAllocationValid(calculatedBuckets, capacity);
  }

  const onCreate = () => {
    onAdd(calculatedBuckets);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const validateFields = () => {
    return values.ageBuckets?.every((bucket: any) => bucket.ageFrom && bucket.ageTo && bucket.allocation && bucket.allocationPercentage);
  };

  const bucketsValidcheck = validateFields();

  const showError = bucketsValidcheck && !bucketsValid;

  const [genderKey, setGenderKey] = useState(0);

  const generateGenderPopulation = (country, capacity) => {
    const countryValues: Record<string, number[]> = {
      NL: [49, 51],
      DE: [49, 51],
      FR: [49, 51],
      BE_FL: [49, 51],
      BE_WL: [48, 52],
      UK: [49, 51],
    };
    const percentages = countryValues[country];
    let rest = capacity
    let femaleAllocation = 0
  
    change('genderType', BUCKET_OPTION_TYPES.nested);

    const simulatedClick = (key) => {
      const percentage = percentages[key === 'MALE' ? 0 : 1];
      const allocation = Math.floor(capacity / 100 * percentage);
      if (key == 'FEMALE') {
        femaleAllocation = allocation
      }
      change(`gender.${key}.checked`, true);
      change(`gender.${key}.allocation`, allocation.toFixed(0));
      change(`gender.${key}.allocationPercentage`, percentage.toFixed(2));
      rest -= allocation 
    };
  
    simulatedClick('MALE');
    simulatedClick('FEMALE');
    change(`gender.FEMALE.allocation`, femaleAllocation + rest);
  };
  
  
  let isGenderTypeNested = values.genderType === BUCKET_OPTION_TYPES.nested;

  return (
    <Modal title="Add bulk buckets" isOpen={isOpen} onClose={onClose}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <div className="row">
              <div className="col-md-12">
                <FieldArray
                  name="ageBuckets"
                  component={AgeBuckets}
                  capacity={capacity}
                  change={change}
                  values={values}
                  generateGenderPopulation={generateGenderPopulation}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <SurveyBucketOptions
                  key={genderKey}
                  name="gender"
                  values={values.gender}
                  label="Gender"
                  change={change}
                  capacity={capacity}
                  options={[
                    { label: 'Male', value: 'MALE' },
                    { label: 'Female', value: 'FEMALE' },
                  ]}
                  isNested={isGenderTypeNested}
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={RadioField}
                  isRequired
                  options={[
                    {
                      value: BUCKET_OPTION_TYPES.nested,
                      label: 'Nested',
                    },
                    {
                      value: BUCKET_OPTION_TYPES.filtered,
                      label: 'Filtered',
                    },
                  ]}
                  name="genderType"
                  label="Gender type"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <SurveyBucketsPreview capacity={capacity} buckets={calculatedBuckets} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormButtons>
        {showError && (
          <span className={styles.disabledMessage}>Please make sure the form is filled in correctly</span>
        )}
        <Button buttonType="button" type="primary" onClick={onCreate} disabled={!bucketsValid || !bucketsValidcheck}>
          {'Add'}
        </Button>
      </FormButtons>
    </Modal>
  );
};

export default reduxForm({
  form: 'AddBulkSurveyBucketModal',
  enableReinitialize: true,
  validate: formValidation,
})(AddBulkSurveyBucketModal);
