import React from 'react'
import MuiTable from '@material-ui/core/Table'
import MuiTableContainer from '@material-ui/core/TableContainer'
import MuiTableBody from '@material-ui/core/TableBody'
import MuiTableRow from '@material-ui/core/TableRow'
import MuiTableCell from '@material-ui/core/TableCell'
import { ContentLoader } from 'components'
import { Query, Paginated } from 'services'
import { ColumnResizer } from './ColumnResizer'
import { DataTablePagination } from './DataTablePagination'
import { Column, DataTableHead } from './DataTableHead'
import { useDataTable } from './hooks'
import { useStyles } from './DataTable.styles'
import styles from './styles.module.scss';

let id = 0;

interface DataTableProps<TData> {
    columns: Column[]
    idField?: keyof TData
    onRowClick: (id: string | number) => void
    requestHandler: (query?: Query) => Promise<Paginated<TData>>
}

export const DataTable = <TData,>({
    columns,
    idField = 'id' as keyof TData,
    onRowClick,
    requestHandler,
}: DataTableProps<TData>) => {
    const { root } = useStyles()
    const {
        data,
        onPageChange,
        onPageSizeChange,
        onSortModelChange,
        pagination,
        requestStatus,
        sortModel,
    } = useDataTable<TData>({
        requestHandler,
    })

    return (
        <>
            <ContentLoader status={requestStatus}>
                <MuiTableContainer>
                    <MuiTable classes={{ root }}>
                        <DataTableHead
                            orderBy={sortModel && sortModel.field}
                            sortingOrder={(sortModel && sortModel.sort) || 'asc'}
                            columns={columns}
                            onSortModelChange={onSortModelChange}
                        />
                        <MuiTableBody>
                            {data.map(row => (
                                <MuiTableRow
                                    key={(row[idField] as unknown) as number}
                                    onClick={() => {
                                        onRowClick((row[idField] as unknown) as number)
                                    }}
                                    hover>
                                    {columns.map((column, index) => (
                                        <>
                                        {index > 0 && <ColumnResizer disabled={true} />}
                                        <MuiTableCell key={row[column.field]}>
                                        {(() => {
                                            const currentUrl = window.location.href;
                                            const appIndex = currentUrl.indexOf('/app/');
                                            if (appIndex !== -1) {
                                                let strippedUrl = currentUrl.substring(appIndex);
                                                strippedUrl = strippedUrl.split('?')[0];
                                                const urlParts = strippedUrl.split('/');
                                                if (urlParts.length > 2) {
                                                    const resource = urlParts[2];
                                                    const part3 = urlParts.length > 3 ? urlParts[3] : null;
                                                    if (resource == "clients") {
                                                        strippedUrl = strippedUrl.replace(resource, `campaigns?client=`);
                                                    } else if (resource == 'users' && part3 == 'edit') {
                                                        strippedUrl = strippedUrl.replace(/\/\d+$/, '');
                                                    } else {
                                                        strippedUrl = strippedUrl.replace(resource, `${resource}/edit`);
                                                    }
                                                }
                                                if (index === 0) {
                                                    id = row[column.field];
                                                }
                                                
                                                const idString = String(id);
                                                const lastTenChars = idString.slice(-10);
                                                const isDate = (() => {
                                                    const dateParts = lastTenChars.split('-');
                                                    if (dateParts.length === 3) {
                                                        const [day, month, year] = dateParts.map(Number);
                                                        if (
                                                            day >= 1 && day <= 31 &&
                                                            month >= 1 && month <= 12 &&
                                                            year >= 1000 && year <= 9999
                                                        ) {
                                                            const testDate = new Date(year, month - 1, day);
                                                            return (
                                                                testDate.getFullYear() === year &&
                                                                testDate.getMonth() === month - 1 &&
                                                                testDate.getDate() === day
                                                            );
                                                        }
                                                    }
                                                    return false;
                                                })();

                                                if (!isDate && Number.isInteger(id)) {
                                                    strippedUrl = `${strippedUrl}/${id}`;
                                                } else if (!isDate) {
                                                    strippedUrl = `${strippedUrl}${id}`;
                                                }
                                                
                                                const urlParams = new URLSearchParams(window.location.search);
                                                const countryParam = urlParams.get("country") ?? "NL";
                                                const separator = strippedUrl.includes("?") ? "&" : "?";
                                                strippedUrl = `${strippedUrl}${separator}country=${countryParam}`;
                                                
                                                if (!isDate) {
                                                    return (
                                                        <a href={strippedUrl} rel="noopener noreferrer" className={styles.tableLink}>
                                                            {column.valueGetter ? column.valueGetter(row) : row[column.field]}
                                                        </a>
                                                    );
                                                }

                                                return (
                                                    <span className={styles.tableText}>
                                                        {column.valueGetter ? column.valueGetter(row) : row[column.field]}
                                                    </span>
                                                );
                                            }
                                            return null;
                                        })()}
                                        </MuiTableCell>
                                    </>
                                ))}
                                </MuiTableRow>
                            ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
            </ContentLoader>
            { pagination?.totalPages > 1 ? <DataTablePagination
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                {...pagination}
            />: <></>}

        </>
    )
}
