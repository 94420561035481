import { Field } from 'redux-form';
import { parseComment, parseNumber } from 'shared/utils/fields';
import { parseFloatNumber } from 'shared/utils/fields';
import SurveyBucketField from '../SurveyBucketField';
import styles from './styles.module.scss';
import Button from 'shared/components/Button';

const calculateAllocationByPercentage = ({ percentage, capacity }) => {
  const cleanedPercentage = parseFloat(percentage);

  if (!capacity || isNaN(cleanedPercentage)) return 0;

  return Math.floor((capacity * cleanedPercentage) / 100);
};
const calculateAllocationPercentageByAllocation = ({ allocation, capacity }) =>
  ((allocation * 100) / capacity).toFixed(2);

interface AgeBucketsTypes {
  fields: any;
  capacity: number;
  change: (...args: any[]) => void;
  generateGenderPopulation: (country: string, capacity: number) => void;
  values: {
    ageBuckets: {
      ageFrom: number;
      ageTo: number;
      allocation: number;
      allocationPercentage: string | number;
      comment: string;
    }[];
  };
}

export const AgeBuckets = ({ fields, capacity, change, generateGenderPopulation, values }: AgeBucketsTypes) => {
  const totalAllocation = (field) => {
    const buckets = values.ageBuckets || [];
    const sum = buckets
      .map((bucket) => bucket[field])
      .filter((number) => !isNaN(number))
      .reduce((a, b) => Number(a) + Number(b), 0);
    return sum;
  };

  const onCommentChange = (ageBucket) => (e) => {
    change(`${ageBucket}.comment`, e.target.value)
  }

  const onAllocationPercentageChange = (ageBucket) => (e) => {
    change(
      `${ageBucket}.allocation`,
      calculateAllocationByPercentage({
        percentage: e.target.value,
        capacity,
      })
    );
  };
  const onAllocationChange = (ageBucket) => (e) => {
    change(
      `${ageBucket}.allocationPercentage`,
      calculateAllocationPercentageByAllocation({
        allocation: e.target.value,
        capacity,
      })
    );
  };

  const splitEvenly = () => {
    if (values.ageBuckets) {
      const activeOptions = Object.keys(values.ageBuckets);
      let totalAllocation = 0;
      const allocationMap: Record<string, number> = {};

      activeOptions.forEach((key) => {
          const percentage = (1 / activeOptions.length) * 100;
          const allocation = Math.round((capacity * percentage) / 100);
          allocationMap[key] = allocation;
          totalAllocation += allocation;
      });

      const difference = capacity - totalAllocation;
      if (difference !== 0) {
          const firstKey = activeOptions[0];
          allocationMap[firstKey] += difference;
      }

      activeOptions.forEach((key) => {
          const newPercentage = ((allocationMap[key] / capacity) * 100).toFixed(2);
          change(`ageBuckets.${key}.allocation`, allocationMap[key]);
          change(`ageBuckets.${key}.allocationPercentage`, newPercentage);
      });
    }
  };

const getCountryFromUrl = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("country") || "NL";
};
const generatePopulation = (capacity: number) => {
  const country = getCountryFromUrl();
  
  generateGenderPopulation(country, capacity);
  
  const countryAllocations: Record<string, number[]> = {
    NL: [11, 16, 15, 16, 17, 25],
    DE: [9, 15, 15, 16, 20, 25],
    FR: [11, 14, 15, 16, 18, 26],
    BE_FL: [10, 15, 16, 16, 17, 26],
    BE_WL: [11, 16, 16, 17, 17, 23],
    UK: [11, 17, 16, 17, 16, 23],
  };

  const percentages = countryAllocations[country] || countryAllocations["NL"];
  const ageGroups = ["18-24", "25-34", "35-44", "45-54", "55-64", "65-99"];

  fields.removeAll();

  let totalAllocation = 0;
  const newBuckets = ageGroups.map((range, index) => {
    const allocation = Math.floor((capacity * percentages[index]) / 100);
    const allocationPercentage = ((allocation / capacity) * 100).toFixed(2);
    totalAllocation += allocation;
    return {
      ageFrom: parseInt(range.split('-')[0], 10),
      ageTo: parseInt(range.split('-')[1], 10),
      allocation,
      allocationPercentage,
    };
  });

  const difference = capacity - totalAllocation;
  if (difference !== 0) {
    newBuckets[0].allocation += difference;
  }

  newBuckets.forEach((bucket) => {
    bucket.allocationPercentage = ((bucket.allocation / capacity) * 100).toFixed(2);
    fields.push(bucket);
  });
};


  

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>#</th>
          <th>Age</th>
          <th>Allocation</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {fields.map((ageBucket, index) => (
          <tr key={index}>
            <td className={styles.index}>{index + 1}</td>
            <td className={styles.left}>
              <div className="d-flex">
                <Field
                  name={`${ageBucket}.ageFrom`}
                  type="text"
                  component={SurveyBucketField}
                  parse={parseNumber}
                />
                -
                <Field
                  name={`${ageBucket}.ageTo`}
                  parse={parseNumber}
                  type="text"
                  component={SurveyBucketField}
                />
              </div>
            </td>
            <td className={styles.right}>
              <div className="d-flex">
                <Field
                  name={`${ageBucket}.allocation`}
                  type="text"
                  component={SurveyBucketField}
                  parse={parseNumber}
                  onChange={onAllocationChange(ageBucket)}
                />
                (
                <Field
                  parse={parseFloatNumber}
                  name={`${ageBucket}.allocationPercentage`}
                  type="text"
                  component={SurveyBucketField}
                  onChange={onAllocationPercentageChange(ageBucket)}
                />
                %)
              </div>
            </td>
            <td className={styles.comments}>
              <Field
                parse={parseComment}
                name={`${ageBucket}.comment`}
                type="text"
                component={SurveyBucketField}
                onChange={onCommentChange(ageBucket)}
                className="commentsInput"
              />
            </td>
            <td>
              <Button
                buttonType="button"
                title="Remove Member"
                onClick={() => fields.remove(index)}
                ultraSmall
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4} className={styles.addBucket}>
            <Button buttonType="button" small onClick={() => generatePopulation(capacity)}>
              GenPop
            </Button>
            <Button buttonType="button" small onClick={splitEvenly}>
              Split evenly
            </Button>
            <Button buttonType="button" small onClick={() => fields.push({})}>
              Add age bucket +
            </Button>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            Allocated {totalAllocation('allocation')}/{capacity} (
            {Math.round(totalAllocation('allocationPercentage'))} / 100%)
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default AgeBuckets;
